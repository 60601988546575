import { AddIcon } from "@chakra-ui/icons";
import {
    Alert,
    AlertIcon,
    Avatar,
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Highlight,
    Image,
    Spinner,
    Stack,
    StackDivider,
    Text,
} from "@chakra-ui/react";
import {
    FC,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ProtectedContext } from "../../providers/ProtectedProvider";
import {
    getMedecin,
    listMedecinsByName,
} from "../../services/medecin.services";
import { listRelationByCabinet } from "../../services/relation.services";
import { baseUrl } from "../../services/services";
import { TMedecin } from "../../types/medecin";
import { TRelationMedecin } from "../../types/relation";
import FormsComponent from "../FormComponent";

type TForm = {
    keyword: string;
};

const MedecinsListComponent: FC = () => {
    const mountedRef = useRef(true);
    const navigate = useNavigate();
    const {
        currentCabinet,
        isModalLink,
        setCurrentMedecin,
        setIsModalLink,
        setCurrentCabinet,
    } = useContext(ProtectedContext);
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const [hasSearch, setHasSearch] = useState<boolean>(false);
    const [tSearch, setTSearch] = useState<Array<TMedecin>>([]);
    const [tRelations, setTRelations] = useState<Array<TRelationMedecin>>([]);
    const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
    const currentForm = useForm<TForm>();

    const linkMedecin = async (uid_medecin: string) => {
        const reqMed = await getMedecin(uid_medecin);
        if (reqMed.length === 1) {
            setCurrentMedecin(reqMed[0]);
            setCurrentCabinet(currentCabinet);
            setIsModalLink(true);
        }
    };

    const refreshRelationList = async () => {
        if (currentCabinet?.id) {
            setTRelations(await listRelationByCabinet(currentCabinet.id));
        }
    };

    const isInMyMedecinList = useCallback(
        (idMedecin: number) => {
            return tRelations.some((item) => item.medecin.id === idMedecin);
        },
        [tRelations.length]
    );

    useEffect(() => {
        if (!isModalLink) {
            (async () => {
                await refreshRelationList();
            })();
        }

        return () => {
            mountedRef.current = false;
        };
    }, [currentCabinet?.id, isModalLink]);

    useEffect(() => {
        const keyword = currentForm.getValues("keyword");
        if (keyword && keyword.length >= 2) {
            setHasSearch(true);
            (async () => {
                setIsSearchLoading(true);
                const res = await listMedecinsByName(keyword);
                setTSearch(res);
                setIsSearchLoading(false);
            })();
        } else {
            setHasSearch(false);
            setTSearch([]);
        }

        return () => {
            mountedRef.current = false;
        };
    }, [currentForm.watch("keyword")]);

    return (
        <Stack width={"600px"} mx={"auto"}>
            <Text textAlign={"center"} fontWeight={700} fontSize={"22px"}>
                Le(s) médecin(s) associé(s) à mon cabinet
            </Text>
            {tRelations.length === 0 && (
                <Alert
                    status="info"
                    my={5}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <AlertIcon />
                    Aucun médecin n'est encore associé à ce cabinet
                </Alert>
            )}

            {tRelations.map((item, key) => (
                <Flex key={key} mt={5}>
                    <Flex alignItems={"center"} gap={2}>
                        <Avatar
                            name={`${item.medecin.nom} ${item.medecin.prenom}`}
                            bg={"#E2E8F0"}
                        />
                        <Text fontSize={"18px"} fontWeight={700}>
                            {item.medecin.nom} {item.medecin.prenom}
                        </Text>
                    </Flex>
                    <Flex ml={"auto"} gap={3}>
                        <Button
                            variant={"outline"}
                            size={"sm"}
                            colorScheme="dark"
                            aria-label="Voir la relation"
                            width={"40px"}
                            height={"40px"}
                            position={"relative"}
                            onClick={() => linkMedecin(item.medecin.uid)}
                        >
                            <Image
                                src={`/images/link-check.svg`}
                                alt=""
                                width={"35px"}
                                height={"35px"}
                                position={"absolute"}
                                top={"6px"}
                                left={"6px"}
                            />
                        </Button>
                        <Button
                            colorScheme="red"
                            height={"40px"}
                            onClick={() =>
                                navigate(`/inner/medecins/${item.medecin.uid}`)
                            }
                        >
                            Voir la fiche
                        </Button>
                    </Flex>
                </Flex>
            ))}
            <Divider />
            <Button
                leftIcon={<AddIcon />}
                colorScheme="red"
                size={"lg"}
                mt={3}
                onClick={() => setOpenSearch(true)}
            >
                Associer un médecin au cabinet
            </Button>

            {openSearch && (
                <>
                    <Text
                        textAlign={"center"}
                        fontWeight={700}
                        fontSize={"22px"}
                        mt={3}
                    >
                        Ajouter un médecin à mon cabinet
                    </Text>
                    <Text
                        textAlign={"center"}
                        fontWeight={700}
                        fontSize={"20px"}
                        mt={1}
                    >
                        Je recherche et j'ajoute un médecin depuis la liste
                    </Text>
                    <Box mt={3}>
                        <FormsComponent
                            form={currentForm}
                            name="keyword"
                            placeholder="Rechercher le nom d'un docteur dans la liste..."
                            type="search"
                        />
                    </Box>
                    {hasSearch && (
                        <>
                            {isSearchLoading && (
                                <Center my={2}>
                                    <Spinner />
                                </Center>
                            )}

                            {tSearch.length === 0 && !isSearchLoading && (
                                <>
                                    <Alert
                                        status="info"
                                        my={5}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                    >
                                        <AlertIcon />
                                        Aucun médecin ne correspond à votre
                                        critère
                                    </Alert>
                                    <Divider />
                                    <Button
                                        leftIcon={<AddIcon />}
                                        size={"lg"}
                                        mt={3}
                                        onClick={() =>
                                            navigate("/inner/medecins/0/edit")
                                        }
                                    >
                                        Créer une fiche médecin
                                    </Button>
                                </>
                            )}

                            {tSearch.length > 0 && (
                                <Stack
                                    divider={<StackDivider />}
                                    spacing="4"
                                    mt={5}
                                >
                                    {tSearch.map((item, key) => (
                                        <Flex
                                            key={key}
                                            alignItems={"center"}
                                            gap={3}
                                            opacity={
                                                isInMyMedecinList(item.id)
                                                    ? 0.5
                                                    : 1
                                            }
                                        >
                                            <Avatar
                                                name={`${item.nom} ${item.prenom}`}
                                                src={
                                                    item.photo?.url
                                                        ? `${baseUrl}${item.photo.url}`
                                                        : undefined
                                                }
                                                bg={"#E2E8F0"}
                                            />
                                            {currentForm.watch("keyword") && (
                                                <Text
                                                    fontFamily={
                                                        "var(--font-secondary)"
                                                    }
                                                    fontWeight={700}
                                                    fontSize={"18px"}
                                                >
                                                    <Highlight
                                                        query={currentForm.getValues(
                                                            "keyword"
                                                        )}
                                                        styles={{
                                                            py: "1",
                                                            bg: "var(--color-red)",
                                                            color: "#FFFFFF",
                                                        }}
                                                    >
                                                        {`${item.nom} ${item.prenom}`}
                                                    </Highlight>
                                                </Text>
                                            )}

                                            <Button
                                                variant={"outline"}
                                                size={"sm"}
                                                colorScheme="dark"
                                                onClick={() =>
                                                    linkMedecin(item.uid)
                                                }
                                                aria-label="Voir la relation"
                                                width={"40px"}
                                                height={"40px"}
                                                position={"relative"}
                                                ml={"auto"}
                                                isDisabled={isInMyMedecinList(
                                                    item.id
                                                )}
                                            >
                                                <Image
                                                    src={
                                                        isInMyMedecinList(
                                                            item.id
                                                        )
                                                            ? "/images/link-check.svg"
                                                            : "/images/link-add.svg"
                                                    }
                                                    alt=""
                                                    width={"35px"}
                                                    height={"35px"}
                                                    position={"absolute"}
                                                    top={"6px"}
                                                    left={"6px"}
                                                />
                                            </Button>
                                        </Flex>
                                    ))}
                                </Stack>
                            )}
                        </>
                    )}
                </>
            )}
        </Stack>
    );
};

export default MedecinsListComponent;
