import { Box, Button, Flex, Stack, Text, useToast } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { AppContext } from "../../../providers/AppProvider";
import { ProtectedContext } from "../../../providers/ProtectedProvider";
import {
    createCabinet,
    getLatLng,
    updateCabinet,
} from "../../../services/cabinet.services";
import FormsComponent from "../../FormComponent";

type TForm = {
    id: number;
    uid: string;
    nom: string;
    departement: string;
    adresse: string;
    adresse_comp_1?: string;
    adresse_comp_2?: string;
    code_postal: string;
    ville: string;
    telephone: string;
    email: string;
    acces_handicapes: string;
    parking: string;
    parking_description: string;
    transport_commun: string;
    transport_commun_desc: string;
    geolocalisation: {
        latitude: number;
        longitude: number;
    };
    users_permissions_user: number;
};

const InfoGenComponent = () => {
    const mountedRef = useRef(true);
    const navigate = useNavigate();
    const toast = useToast();
    const {
        currentCabinet,
        departements,
        setCurrentCabinet,
        loadDepartements,
        setActiveTabCabinet,
    } = useContext(ProtectedContext);
    const { user } = useContext(AppContext);
    const [title, setTitle] = useState<string>("");
    const [btnLabel, setBtnLabel] = useState<string>("");
    const currentForm = useForm<TForm>();

    const onSubmit: SubmitHandler<TForm> = async (formData) => {
        if (!currentCabinet) {
            // Récupération de la géolocalisation
            const reqGeoloc = await getLatLng(
                [
                    formData.adresse,
                    formData.adresse_comp_1,
                    formData.adresse_comp_2,
                    formData.code_postal,
                    formData.ville,
                ].join(" ")
            );

            if (reqGeoloc) {
                formData.geolocalisation = {
                    latitude: reqGeoloc.lat,
                    longitude: reqGeoloc.lon,
                };
            } else {
                currentForm.setError("adresse", {
                    message:
                        "Impossible de localiser le cabinet. Veuillez vérifier ce champ.",
                });
                currentForm.setFocus("adresse");
                return;
            }
        }

        formData.users_permissions_user = user.id;

        let appReq;
        if (!currentCabinet) {
            formData.uid = uuid();
            appReq = await createCabinet(formData);
            if (appReq.data.attributes) {
                navigate(`/inner/cabinets/${appReq.data.attributes.uid}/edit`);
                toast({
                    description: "Nouveau cabinet bien ajouté.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            formData.uid = currentCabinet.uid;
            appReq = await updateCabinet(formData);

            if (appReq.data.attributes) {
                toast({
                    description: "Cabinet bien enregistré.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }

        // Update current cabinet status
        setCurrentCabinet({ ...appReq.data.attributes, id: appReq.data.id });

        // Go to next tab
        setActiveTabCabinet(1);
    };

    useEffect(() => {
        if (departements.length === 0) {
            loadDepartements();
        }

        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        if (!currentCabinet) {
            setTitle("Création d'une fiche cabinet");
            setBtnLabel("Enregistrer le cabinet");
            currentForm.reset({ departement: "1" });
        } else {
            setTitle("Modification des informations du cabinet");
            setBtnLabel("Valider les modifications");

            // Reset form
            currentForm.reset({
                id: currentCabinet.id,
                uid: currentCabinet.uid,
                nom: currentCabinet.nom,
                departement: currentCabinet.departement,
                adresse: currentCabinet.adresse,
                adresse_comp_1: currentCabinet.adresse_comp_1,
                adresse_comp_2: currentCabinet.adresse_comp_2,
                code_postal: currentCabinet.code_postal,
                ville: currentCabinet.ville,
                telephone: currentCabinet.telephone,
                email: currentCabinet.email,
                acces_handicapes: currentCabinet.acces_handicapes,
                parking: currentCabinet.parking,
                parking_description: currentCabinet.parking_description,
                transport_commun: currentCabinet.transport_commun,
                transport_commun_desc: currentCabinet.transport_commun_desc,
                users_permissions_user: currentCabinet.users_permissions_user,
            });
        }

        return () => {
            mountedRef.current = false;
        };
    }, [currentCabinet?.uid]);

    return (
        <>
            <Text textAlign={"center"} fontWeight={700} fontSize={"22"}>
                {title}
            </Text>
            <form onSubmit={currentForm.handleSubmit(onSubmit)}>
                <Stack
                    spacing={8}
                    direction={"column"}
                    my={5}
                    width={"600px"}
                    mx="auto"
                >
                    <FormsComponent
                        form={currentForm}
                        name="nom"
                        label="Nom du cabinet"
                        placeholder="Cabinet A"
                        type="text"
                        required
                    />

                    <FormsComponent
                        form={currentForm}
                        name="departement"
                        label="Département"
                        defaultValue={currentCabinet?.departement || "1"}
                        options={departements.map((item) => ({
                            key: item.id,
                            label: `${item.code}-${item.nom}`,
                        }))}
                        type="select"
                        required
                    />

                    <FormsComponent
                        form={currentForm}
                        name="adresse"
                        label="Adresse"
                        placeholder="Adresse"
                        type="text"
                        required
                    />
                    <FormsComponent
                        form={currentForm}
                        name="adresse_comp_1"
                        placeholder="Complément d'adresse (facultatif)"
                        type="text"
                    />
                    <FormsComponent
                        form={currentForm}
                        name="adresse_comp_2"
                        placeholder="Complément d'adresse 2 (facultatif)"
                        type="text"
                    />
                    <Flex direction={"row"} gap={5}>
                        <Box flex={2}>
                            <FormsComponent
                                form={currentForm}
                                name="code_postal"
                                placeholder="Code postal"
                                type="text"
                                minLength={5}
                                maxLength={5}
                                required
                            />
                        </Box>
                        <Box flex={4}>
                            <FormsComponent
                                form={currentForm}
                                name="ville"
                                placeholder="Ville"
                                type="text"
                                required
                            />
                        </Box>
                    </Flex>

                    <FormsComponent
                        form={currentForm}
                        name="telephone"
                        label="Téléphone"
                        placeholder="ex : +331 23 45 67 89"
                        type="tel"
                        required
                    />

                    <FormsComponent
                        form={currentForm}
                        name="email"
                        label="Email"
                        placeholder="Email"
                        type="email"
                        required
                    />

                    <FormsComponent
                        form={currentForm}
                        name="acces_handicapes"
                        label="Accès handicapés"
                        type="radio"
                        defaultValue={currentCabinet?.acces_handicapes}
                        options={[
                            {
                                key: "non",
                                label: "Non",
                            },
                            {
                                key: "oui",
                                label: "Oui",
                            },
                        ]}
                        required
                    />

                    <FormsComponent
                        form={currentForm}
                        name="parking"
                        label="Parking"
                        type="radio"
                        defaultValue={currentCabinet?.parking}
                        options={[
                            {
                                key: "non",
                                label: "Non",
                            },
                            {
                                key: "gratuit",
                                label: "Gratuit",
                            },
                            {
                                key: "payant",
                                label: "Payant",
                            },
                            {
                                key: "reserve_patients",
                                label: "Réservé aux patients",
                            },
                        ]}
                        required
                    />
                    {currentForm.watch("parking") &&
                        currentForm.watch("parking") !== "non" && (
                            <FormsComponent
                                form={currentForm}
                                name="parking_description"
                                placeholder="Description de l'accès au parking"
                                type="textarea"
                            />
                        )}

                    <FormsComponent
                        form={currentForm}
                        name="transport_commun"
                        label="Accès transports en commun"
                        type="radio"
                        defaultValue={currentCabinet?.transport_commun}
                        options={[
                            {
                                key: "non",
                                label: "Non",
                            },
                            {
                                key: "oui",
                                label: "Oui",
                            },
                        ]}
                        required
                    />

                    {currentForm.watch("transport_commun") &&
                        currentForm.watch("transport_commun") === "oui" && (
                            <FormsComponent
                                form={currentForm}
                                name="transport_commun_desc"
                                placeholder="Description de l’accès transport en commun"
                                type="textarea"
                            />
                        )}

                    <Button type="submit" colorScheme="red" size={"lg"}>
                        {btnLabel}
                    </Button>
                </Stack>
            </form>
        </>
    );
};

export default InfoGenComponent;
