import { Box, Button, Stack, Text, useToast } from "@chakra-ui/react";
import { FC, useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ProtectedContext } from "../../providers/ProtectedProvider";
import { updateRelation } from "../../services/relation.services";
import FormsComponent from "../FormComponent";

type TForm = {
    solution_resa: string;
    solution_resa_alaxione: string;
    solution_resa_doctolib: string;
    solution_resa_custom: string;
    solution_resa_tel: string;
};

const ModalSolutionResaComponent: FC = () => {
    const toast = useToast();
    const { currentRelations, setCurrentRelations, setActiveModalRelationTab, setIsModalLink } =
        useContext(ProtectedContext);

    const currentForm = useForm<TForm>();
    const onSubmit: SubmitHandler<TForm> = async (formData) => {
        if (currentRelations) {
            setCurrentRelations(
                await updateRelation({
                    ...formData,
                    id: currentRelations.id,
                })
            );
            toast({
                description: "Les informations sont bien enregistrées",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            setIsModalLink(false);
        }
    };
    return (
        <>
            {currentRelations && (
                <form onSubmit={currentForm.handleSubmit(onSubmit)}>
                    <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        gap={4}
                    >
                        <Text
                            fontSize={"22px"}
                            align={"center"}
                            fontWeight={700}
                        >
                            Votre solution de réservation
                        </Text>
                        <Stack width={"400px"} gap={3}>
                            <FormsComponent
                                form={currentForm}
                                name="solution_resa"
                                type="select"
                                defaultValue={currentRelations.solution_resa}
                                options={[
                                    {
                                        key: "",
                                        label: "Je choisis ma solution de réservation",
                                    },
                                    { key: "alaxione", label: "Alaxione" },
                                    { key: "doctolib", label: "Doctolib" },
                                    {
                                        key: "custom",
                                        label: "Ma propre solution de réservation",
                                    },
                                    {
                                        key: "phone",
                                        label: "Je souhaite que la prise de rendez-vous se fasse par téléphone",
                                    },
                                ]}
                                required
                            />
                            {currentForm.watch("solution_resa") && (
                                <Box mt={3}>
                                    {currentForm.watch("solution_resa") ===
                                        "alaxione" && (
                                        <FormsComponent
                                            form={currentForm}
                                            name="solution_resa_alaxione"
                                            defaultValue={currentRelations.solution_resa_alaxione}
                                            type="text"
                                            label="Champ ID Alaxione du médecin"
                                            placeholder="ex: ID"
                                            required
                                        />
                                    )}

                                    {currentForm.watch("solution_resa") ===
                                        "doctolib" && (
                                        <FormsComponent
                                            form={currentForm}
                                            name="solution_resa_doctolib"
                                            defaultValue={currentRelations.solution_resa_doctolib}
                                            type="text"
                                            label='Url Doctolib avec Motif "IVT Mobility"'
                                            placeholder="Url Doctolib"
                                            required
                                        />
                                    )}

                                    {currentForm.watch("solution_resa") ===
                                        "custom" && (
                                        <FormsComponent
                                            form={currentForm}
                                            name="solution_resa_custom"
                                            defaultValue={currentRelations.solution_resa_custom}
                                            type="text"
                                            label="URL de ma solution de réservation"
                                            placeholder="Ex: https://www.mon-url-personnalise.fr"
                                            required
                                        />
                                    )}

                                    {currentForm.watch("solution_resa") ===
                                        "phone" && (
                                        <FormsComponent
                                            form={currentForm}
                                            name="solution_resa_tel"
                                            defaultValue={currentRelations.solution_resa_tel}
                                            type="text"
                                            label="Votre numéro de téléphone"
                                            placeholder="Ex: +33 5 56 51 16 16"
                                            required
                                        />
                                    )}
                                </Box>
                            )}
                        </Stack>
                        <Button
                            size={"lg"}
                            colorScheme="red"
                            type="submit"
                            width={"100%"}
                        >
                            Valider
                        </Button>
                        <Button
                            size={"lg"}
                            variant={"outline"}
                            width={"100%"}
                            onClick={() => setActiveModalRelationTab(1)}
                        >
                            Retour
                        </Button>
                    </Stack>
                </form>
            )}
        </>
    );
};

export default ModalSolutionResaComponent;
