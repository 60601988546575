import { AddIcon } from "@chakra-ui/icons";
import {
    AbsoluteCenter,
    Box,
    Button,
    Divider,
    Flex,
    Image,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import RoundedBoxComponent from "../components/RoundedBoxComponent";
import { isLogged } from "../helpers/session.helper";

const HomePage = () => {
    const mountedRef = useRef(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (isLogged()) {
            navigate("/inner");
        }

        return () => {
            mountedRef.current = false;
        };
    }, []);

    const tDemarches = [
        {
            num: 1,
            label: "S'inscrire sur ce site en renseignant vos coordonnées.",
        },
        {
            num: 2,
            label: "Compléter les informations sur le(s) cabinet(s) d'ophtalmologie, site(s) de réalisation d'IVT et le(s) praticien(s).",
        },
        {
            num: 3,
            label: "Renseigner les créneaux d'injection.",
        },
    ];

    return (
        <RoundedBoxComponent centerVertical>
            <Box>
                <Image
                    src="/images/logo.svg"
                    alt=""
                    width={"236px"}
                    mx={"auto"}
                />
            </Box>
            <Box
                textAlign={"center"}
                fontWeight={700}
                fontSize={"20px"}
                fontFamily={"var(--font-secondary)"}
                mt={8}
                mb={4}
            >
                Le site de référencement des cabinets ophtalmologiques conçu
                pour simplifier les déplacements des patients !
            </Box>
            <Divider h={"2px"} />
            <Box
                mt={4}
                textTransform={"uppercase"}
                fontSize={"20px"}
                fontWeight={700}
                textAlign={"center"}
            >
                Une démarche simple et rapide
            </Box>
            <Box mt={4}>
                {tDemarches.map((item) => (
                    <Flex
                        key={item.num}
                        fontWeight={700}
                        fontSize={"18px"}
                        my={3}
                    >
                        <Box
                            width={"34px"}
                            height={"34px"}
                            borderRadius={"100%"}
                            bg={"var(--color-red)"}
                            color={"#FFFFFF"}
                            flexShrink={0}
                            textAlign={"center"}
                            lineHeight={"34px"}
                        >
                            {item.num}
                        </Box>
                        <Box mt={1} ml={2}>
                            {item.label}
                        </Box>
                    </Flex>
                ))}
            </Box>
            <Box mt={10}>
                <Button
                    leftIcon={<AddIcon />}
                    colorScheme={"red"}
                    size={"lg"}
                    w={"100%"}
                    onClick={() => navigate("/register")}
                >
                    Inscrire mon cabinet d'ophtalmologie
                </Button>
            </Box>
            <Box position="relative" padding="10">
                <Divider />
                <AbsoluteCenter bg="white" px="4" fontWeight={700}>
                    OU
                </AbsoluteCenter>
            </Box>
            <Box>
                <Button
                    size={"lg"}
                    w={"100%"}
                    colorScheme="black"
                    variant={"link"}
                    onClick={() => navigate("/login")}
                >
                    Se connecter
                </Button>
            </Box>
        </RoundedBoxComponent>
    );
};

export default HomePage;
