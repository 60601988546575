import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Image, Text, useToast } from "@chakra-ui/react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { ProtectedContext } from "../../providers/ProtectedProvider";
import { addImage, deleteMedia } from "../../services/media.services";
import { baseUrl } from "../../services/services";
import PopoverDeleteImage from "../PopoverDeleteImage";
import { getMedecin } from "../../services/medecin.services";

const MedecinsImageComponent: FC = () => {
    const mountedRef = useRef(true);
    const { currentMedecin, setCurrentMedecin } = useContext(ProtectedContext);
    const inputPhotoRef = useRef<HTMLInputElement>(null);
    const [photoUrl, setPhotoUrl] = useState<string>("");
    const toast = useToast();

    const refreshMedecin = async () => {
        if (currentMedecin) {
            const req = await getMedecin(currentMedecin.uid);

            if (req.length === 1) {
                setCurrentMedecin(req[0]);
            }
        }
    };

    const addLogo = async () => {
        const tFiles = inputPhotoRef.current?.files;

        if (tFiles) {
            if (currentMedecin) {
                const uploadLogo = await addImage({
                    files: tFiles[0],
                    refId: currentMedecin.id.toString(),
                    ref: "api::medecin.medecin",
                    field: "photo",
                });

                if (uploadLogo.url) {
                    setPhotoUrl(uploadLogo.url);
                    await refreshMedecin();
                    toast({
                        description: "Photo bien ajoutée.",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                    });
                }
            }
        }
    };

    const deleteLogo = async () => {
        if (currentMedecin?.photo) {
            const req = await deleteMedia(currentMedecin.photo.id);
            if (req.id) {
                await refreshMedecin();
                toast({
                    description: "Photo supprimée.",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                });
                setPhotoUrl("");
            }
        }
    };

    useEffect(() => {
        if (currentMedecin?.photo?.id) {
            setPhotoUrl(currentMedecin.photo.url);
        } else {
            setPhotoUrl("");
        }

        return () => {
            mountedRef.current = false;
        };
    }, [currentMedecin?.id]);

    return (
        <>
            <Box
                width={"100%"}
                borderRadius={"12px"}
                border={"1px var(--color-blue) solid"}
                p={4}
            >
                <Text
                    fontSize={"16px"}
                    fontWeight={700}
                    color={"#444444"}
                    textAlign={"center"}
                >
                    Télécharger la photo du médecin
                </Text>
                <input
                    type="file"
                    accept="image/png, image/jpeg"
                    ref={inputPhotoRef}
                    style={{ display: "none" }}
                    onChange={() => addLogo()}
                ></input>
                <Text
                    fontSize={"12px"}
                    fontStyle={"italic"}
                    color={"#444444"}
                    textAlign={"center"}
                >
                    Format accepté : jpg, png. Poids maximal : 1 Mo.
                </Text>
                {!photoUrl && (
                    <Box mt={4} textAlign={"center"}>
                        <Button
                            onClick={() => inputPhotoRef.current?.click()}
                            leftIcon={<AddIcon color={"var(--color-red)"} />}
                            variant={"outline"}
                        >
                            Ajouter une photo
                        </Button>
                    </Box>
                )}

                {photoUrl && (
                    <Flex
                        textAlign={"center"}
                        mt={"30px"}
                        mx={"auto"}
                        pos={"relative"}
                        width={"200px"}
                        height={"200px"}
                        border={"1px var(--color-blue) solid"}
                        p={2}
                        alignItems={"center"}
                        justifyContent={"center"}
                        borderRadius={"100%"}
                    >
                        <PopoverDeleteImage action={deleteLogo} />
                        <Image
                            src={`${baseUrl}${photoUrl}`}
                            alt=""
                            maxW={"176px"}
                            maxH={"176px"}
                            loading="lazy"
                            borderRadius={"100%"}
                        />
                    </Flex>
                )}
            </Box>
        </>
    );
};

export default MedecinsImageComponent;
