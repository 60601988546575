import { TCabinet } from "../types/cabinet";
import { appRequest, appRequestAbs } from "./services";

export const getMyCabinetsList = async (uid: string) => {
    const currentRequest = await appRequest<{
        data: Array<{ id: number; attributes: TCabinet }>;
    }>({
        url: `cabinets?fields[0]=id&fields[1]=nom&fields[2]=uid&filters[status][$eq]=published&filters[users_permissions_user][uid][$eq]=${uid}`,
    });

    return currentRequest.data.data.map((item) => ({
        ...item.attributes,
        id: item.id,
    }));
};

export const getMyCabinetDetails = async (
    uid: string,
    cabinetId: string
): Promise<Array<TCabinet>> => {
    const currentRequest = await appRequest<{
        data: Array<{ id: number; attributes: any }>;
    }>({
        url: `cabinets?populate=*&filters[status][$eq]=published&filters[users_permissions_user][uid][$eq]=${uid}&filters[uid][$eq]=${cabinetId}`,
    });

    return currentRequest.data.data.map((item) => {
        let images;

        if (item.attributes.images.data) {
            images = item.attributes.images.data.map((elm: any) => ({
                id: elm.id,
                filename: elm.attributes.name,
                url: elm.attributes.url,
            }));
        }

        return {
            ...item.attributes,
            departement: item.attributes.departement.data.id,
            users_permissions_user:
                item.attributes.users_permissions_user.data.id,
            logo: {
                id: item.attributes.logo.data?.id,
                filename: item.attributes.logo.data?.attributes.name,
                url: item.attributes.logo.data?.attributes.url,
            },
            id: item.id,
            images,
        };
    });
};

export const createCabinet = async (data: any) => {
    data.status = "published";
    const currentRequest = await appRequest<{
        data: { id: number; attributes: TCabinet };
    }>({
        url: "cabinets?populate=*",
        method: "POST",
        data: { data },
    });

    return currentRequest.data;
};

export const updateCabinet = async (data: any) => {
    const currentRequest = await appRequest<{
        data: { id: number; attributes: TCabinet };
    }>({
        url: `cabinets/${data.id}?populate=*`,
        method: "PUT",
        data: { data },
    });

    return currentRequest.data;
};

export const deleteCabinet = async (id: number) => {
    const currentRequest = await appRequest<{
        data: { id: number; attributes: TCabinet };
    }>({
        url: `cabinets/${id}?populate=*`,
        method: "PUT",
        data: {
            data: {
                status: "deleted",
            },
        },
    });

    return currentRequest.data;
};

export const getLatLng = async (addresse: string) => {
    type TLatLon = {
        geometry: {
            coordinates: Array<number>;
        };
    };
    const currentRequest = await appRequestAbs<{
        data: { features: Array<TLatLon> };
    }>({
        url: `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(
            addresse
        )}`,
    });

    const tFeatures = currentRequest.data.features;

    if (tFeatures.length === 0) {
        return null;
    }

    return {
        lon: tFeatures[0].geometry.coordinates[0],
        lat: tFeatures[0].geometry.coordinates[1],
    };
};
