import { Box, Button, Flex, Stack, Text } from "@chakra-ui/react";
import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ProtectedContext } from "../../../providers/ProtectedProvider";
import { THoraire } from "../../../types/cabinet";

const HorairesDetailsComponent: FC = () => {
    const navigate = useNavigate();
    const { currentCabinet } = useContext(ProtectedContext);
    type TKey = keyof typeof currentCabinet;
    const tKey: Array<{
        key: TKey;
        label: string;
    }> = [
        { key: "horaires_lundi" as TKey, label: "Lundi" },
        { key: "horaires_mardi" as TKey, label: "Mardi" },
        { key: "horaires_mercredi" as TKey, label: "Mercredi" },
        { key: "horaires_jeudi" as TKey, label: "Jeudi" },
        { key: "horaires_vendredi" as TKey, label: "Vendredi" },
        { key: "horaires_samedi" as TKey, label: "Samedi" },
        { key: "horaires_dimanche" as TKey, label: "Dimanche" },
    ];

    return (
        <>
            {currentCabinet && (
                <>
                    <Text textAlign={"center"} fontWeight={700} fontSize={"22"}>
                        Horaires d'accueil
                    </Text>
                    <Stack>
                        <Flex mt={6} gap={"30px"}>
                            <Box width={"176px"} flexShrink={0}>
                                {/* {currentCabinet.logo?.url ? (
                                    <Image
                                        src={`${baseUrl}${currentCabinet.logo.url}`}
                                        alt=""
                                        maxW={"176px"}
                                        maxH={"176px"}
                                        loading="lazy"
                                    />
                                ) : (
                                    <Image
                                        src="/images/default-logo.svg"
                                        alt=""
                                    />
                                )} */}
                            </Box>
                            <Stack gap={4}>
                                {tKey.map((item, key) => {
                                    const tElm = currentCabinet[
                                        item.key
                                    ] as Array<THoraire>;
                                    return (
                                        <Box key={key} fontSize={"16px"}>
                                            <Text fontWeight={700}>
                                                {item.label}
                                            </Text>

                                            {tElm.length === 0 && (
                                                <Text>Fermé</Text>
                                            )}

                                            {tElm.map((subitem, subkey) => (
                                                <Text key={subkey}>
                                                    De {subitem.heure_debut}h
                                                    {subitem.minute_debut !==
                                                        "00" &&
                                                        subitem.minute_debut}{" "}
                                                    à {subitem.heure_fin}h
                                                    {subitem.minute_fin !==
                                                        "00" &&
                                                        subitem.minute_fin}
                                                </Text>
                                            ))}
                                        </Box>
                                    );
                                })}

                                {currentCabinet.horaires_specifiques && (
                                    <Box>
                                        <Text fontWeight={700}>
                                            Fermetures ou horaires spécifiques
                                        </Text>
                                        <Text>
                                            {
                                                currentCabinet.horaires_specifiques
                                            }
                                        </Text>
                                    </Box>
                                )}
                            </Stack>
                        </Flex>
                        <Button
                            colorScheme="red"
                            size={"lg"}
                            mt={5}
                            onClick={() =>
                                navigate(
                                    `/inner/cabinets/${currentCabinet.uid}/edit`
                                )
                            }
                        >
                            Modifier les horaires
                        </Button>
                    </Stack>
                </>
            )}
        </>
    );
};

export default HorairesDetailsComponent;
