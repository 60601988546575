import {
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Spinner,
    Text,
} from "@chakra-ui/react";
import axios from "axios";
import {
    Dispatch,
    FC,
    PropsWithChildren,
    createContext,
    useEffect,
    useState,
} from "react";
import { TUser } from "../types/user";

type TApp = {
    isMobile: boolean;
    user: TUser;
    setUser: Dispatch<TUser>;
};
type TAppProvider = PropsWithChildren<{}>;

const defaultState: TApp = {
    isMobile: false,
    user: {
        id: 0,
        uid: "",
        civilite: "",
        email: "",
        nom: "",
        prenom: "",
        poste: "",
        username: "",
        blocked: false,
        confirmed: false,
        role: {
            id: -999,
        },
    },
    setUser: () => {},
};

export const AppContext = createContext<TApp>(defaultState);

export const AppProvider: FC<TAppProvider> = ({ children }) => {
    const [isMobile, setIsMobile] = useState<boolean>(defaultState.isMobile);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [user, setUser] = useState<TUser>(defaultState.user);

    const handleWindowSizeChange = () => {
        setIsMobile(window.innerWidth <= 479);
    };

    useEffect(() => {
        window.addEventListener("load", handleWindowSizeChange);
        window.addEventListener("resize", handleWindowSizeChange);

        // Add a request interceptor
        let numberOfAjaxCAllPending = 0;

        axios.interceptors.request.use(
            function (config) {
                numberOfAjaxCAllPending++;

                if (config.data?.loading !== false) {
                    setIsLoading(true);
                }

                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );

        // Add a response interceptor
        axios.interceptors.response.use(
            function (response) {
                numberOfAjaxCAllPending--;

                if (numberOfAjaxCAllPending === 0) {
                    setIsLoading(false);
                }
                return response;
            },
            function (error) {
                numberOfAjaxCAllPending--;
                if (numberOfAjaxCAllPending === 0) {
                    setIsLoading(false);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            window.removeEventListener("load", handleWindowSizeChange);
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return (
        <>
            <AppContext.Provider
                value={{
                    isMobile,
                    user,
                    setUser,
                }}
            >
                <Modal
                    closeOnOverlayClick={false}
                    isOpen={isLoading}
                    onClose={() => {}}
                    isCentered
                    size={"sm"}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalBody>
                            <Flex
                                alignItems={"center"}
                                justifyContent={"center"}
                                height={"250px"}
                                direction={"column"}
                            >
                                <Spinner size={"xl"} color="red" />
                                <Text mt={8}>Chargement</Text>
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
                {children}
            </AppContext.Provider>
        </>
    );
};
