import {
    Box,
    Button,
    Divider,
    Image,
    Stack,
    Text,
    useToast,
} from "@chakra-ui/react";
import { FC, useEffect, useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import FormsComponent from "../components/FormComponent";
import RoundedBoxComponent from "../components/RoundedBoxComponent";
import { isLogged } from "../helpers/session.helper";
import { resetPassword } from "../services/user.services";

type TResetPassword = {
    password: string;
    passwordConfirmation: string;
    code: string;
};
const NewPasswordPage: FC = () => {
    const mountedRef = useRef(true);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const recoveryForm = useForm<TResetPassword>();
    const toast = useToast();

    const onSubmit: SubmitHandler<TResetPassword> = async (formData) => {
        if (formData.password !== formData.passwordConfirmation) {
            recoveryForm.setError("passwordConfirmation", {
                message:
                    "Les mots de passe doivent être identique. Veuillez vérifier.",
            });
            return;
        }

        const reqReset = await resetPassword(
            formData.code,
            formData.password,
            formData.passwordConfirmation
        );

        if (reqReset.error) {
            if (reqReset.error.message === "Incorrect code provided") {
                toast({
                    title: "Réinitialisation de mot de passe",
                    description:
                        "Le code de réinitialisation que vous avez fourni n'est pas valide.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }
        } else {
            toast({
                title: "Réinitialisation de mot de passe",
                description: "Votre nouveau mot de passe est bien enregistré.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            navigate("/login");
        }
    };

    useEffect(() => {
        if (isLogged()) {
            navigate("/inner");
        }

        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        if (!searchParams.get("code")) {
            navigate("/");
        } else {
            recoveryForm.reset({
                code: searchParams.get("code")?.toString(),
            });
        }

        return () => {
            mountedRef.current = false;
        };
    }, [searchParams.get("code")]);

    return (
        <RoundedBoxComponent centerVertical>
            <Box>
                <Image
                    src="/images/logo.svg"
                    alt=""
                    width={"236px"}
                    mx={"auto"}
                    mb={8}
                />
            </Box>
            <Divider width={"300px"} mx={"auto"} mb={3} />
            <Text
                textTransform={"uppercase"}
                textAlign={"center"}
                fontSize={"20px"}
                fontWeight={700}
            >
                Réinitialisation du mot de passe
            </Text>
            <form onSubmit={recoveryForm.handleSubmit(onSubmit)}>
                <Stack spacing={8} direction={"column"} my={5}>
                    <FormsComponent
                        form={recoveryForm}
                        name="password"
                        label="Mot de passe"
                        placeholder="Mot de passe"
                        type="password"
                        required={true}
                    />
                    <FormsComponent
                        form={recoveryForm}
                        name="passwordConfirmation"
                        label="Confirmation de mot de passe"
                        placeholder="Confirmation de mot de passe"
                        type="password"
                        required={true}
                    />

                    <Box textAlign={"center"}>
                        <Button
                            type="submit"
                            colorScheme={"red"}
                            size={"lg"}
                            w={"100%"}
                        >
                            Réinitialiser le mot de passe
                        </Button>
                    </Box>
                    <Box
                        textAlign={"center"}
                        fontSize={"18px"}
                        transition={"all .25s ease-in-out"}
                        _hover={{ textDecor: "underline" }}
                    >
                        <Link to={"/"}>
                            <Box as="span">Retour vers l'accueil</Box>
                        </Link>
                    </Box>
                </Stack>
            </form>
        </RoundedBoxComponent>
    );
};

export default NewPasswordPage;
