import Cookies from 'js-cookie';
const LOGIN_SESSION_NAME = "token";

export const getToken = (): string => {
    return Cookies.get(LOGIN_SESSION_NAME) || "";
};

export const isLogged = (): boolean => {
    return !!getToken();
};

export const setLogged = (token: string): void => {
    Cookies.set(LOGIN_SESSION_NAME, token)
};

export const logoutUser = (): void => {
    Cookies.remove(LOGIN_SESSION_NAME);
};
