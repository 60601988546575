import { IconButton, Popover, PopoverTrigger, Image, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, PopoverFooter, ButtonGroup, Button } from "@chakra-ui/react";
import { FC } from "react";

const PopoverDeleteImage: FC<{ action: () => void }> = ({ action }) => {
    return (
        <Popover>
            {({ isOpen, onClose }) => (
                <>
                    <PopoverTrigger>
                        <IconButton
                            variant={"outline"}
                            aria-label="Supprimer"
                            p={2}
                            width={"40px"}
                            height={"40px"}
                            bg={"#FFFFFF"}
                            pos={"absolute"}
                            top={"-20px"}
                            right={"-20px"}
                            icon={
                                <Image
                                    src="/images/trash.svg"
                                    alt={"Supprimer"}
                                    width={"24px"}
                                    height={"24px"}
                                />
                            }
                        />
                    </PopoverTrigger>
                    <PopoverContent bg="#fcfcfc">
                        <PopoverArrow bg="#fcfcfc" />
                        <PopoverCloseButton />
                        <PopoverHeader>Confirmation</PopoverHeader>
                        <PopoverBody>
                            Voulez-vous vraiment supprimer cette image ?
                        </PopoverBody>
                        <PopoverFooter display="flex" justifyContent="flex-end">
                            <ButtonGroup size="sm">
                                <Button variant="outline" onClick={onClose}>
                                    Annuler
                                </Button>
                                <Button
                                    colorScheme="red"
                                    onClick={() => action()}
                                >
                                    Supprimer
                                </Button>
                            </ButtonGroup>
                        </PopoverFooter>
                    </PopoverContent>
                </>
            )}
        </Popover>
    );
};

export default PopoverDeleteImage;