import {
    Avatar,
    Box,
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useMultiStyleConfig,
    useTab,
} from "@chakra-ui/react";
import { FC, forwardRef, useContext, useEffect, useRef } from "react";
import { ProtectedContext } from "../../providers/ProtectedProvider";
import { baseUrl } from "../../services/services";
import ModalCabinetComponent from "./ModalCabinetComponent";
import { useNavigate } from "react-router-dom";
import { getRelationByCabinetMedecin } from "../../services/relation.services";
import ModalPieceRdvComponent from "./ModalPieceRdvComponent";
import ModalSolutionResaComponent from "./ModalSolutionResaComponent";

const ModalLinkComponent: FC = () => {
    const mountedRef = useRef(true);
    const navigate = useNavigate();
    const {
        isModalLink,
        setIsModalLink,
        currentMedecin,
        currentCabinet,
        cabinets,
        currentRelations,
        setCurrentRelations,
        activeModalRelationTab,
        setActiveModalRelationTab,
    } = useContext(ProtectedContext);

    const TabItemTitle = forwardRef<any, { numero: number; label: string }>(
        (props, ref) => {
            const tabProps = useTab({ ...props, ref });
            const isSelected = !!tabProps["aria-selected"];
            const styles = useMultiStyleConfig("Tabs", tabProps);

            return (
                <Button
                    __css={styles.tab}
                    {...tabProps}
                    display={"flex"}
                    alignItems={"center"}
                    gap={2}
                    px={3}
                    whiteSpace={"normal"}
                    fontWeight={700}
                    color={isSelected ? "#444444" : "#E2E8F0"}
                    flex={1}
                    justifyContent={"flex-start"}
                    textAlign={"left"}
                    lineHeight={"24px"}
                    isDisabled={!currentRelations && props.numero !== 1}
                    borderColor={
                        isSelected ? "var(--color-red) !important" : "#E2E8F0"
                    }
                >
                    <Box
                        bg={isSelected ? "var(--color-red)" : "transparent"}
                        color={isSelected ? "#FFFFFF" : "#E2E8F0"}
                        border={"1px #444444 solid"}
                        borderColor={isSelected ? "transparent" : "#E2E8F0"}
                        width={"34px"}
                        height={"34px"}
                        flexShrink={0}
                        as="span"
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        borderRadius={"100%"}
                    >
                        {props.numero}
                    </Box>
                    {props.label}
                </Button>
            );
        }
    );

    const getCurrentRelation = async () => {
        if (currentCabinet && currentMedecin) {
            const req = await getRelationByCabinetMedecin(
                currentCabinet.id,
                currentMedecin.id
            );

            if (req.length === 1) {
                setCurrentRelations(req[0]);
            } else {
                setCurrentRelations(null);
            }
        }
    };

    useEffect(() => {
        if (isModalLink) {
            getCurrentRelation();
        } else {
            setCurrentRelations(null);
            setActiveModalRelationTab(0);
        }

        return () => {
            mountedRef.current = false;
        };
    }, [isModalLink]);

    return (
        <>
            <Modal
                closeOnOverlayClick={false}
                isOpen={isModalLink}
                onClose={() => setIsModalLink(false)}
            >
                <ModalOverlay />
                <ModalContent width={"800px"} maxW={"unset"}>
                    <ModalHeader textAlign={"center"} fontSize={"20px"}>
                        Modification des informations d’association
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {cabinets.length === 0 && (
                            <>
                                <Text align={"center"} fontWeight={700} mb={3}>
                                    Vous n'avez pas encore de cabinet à
                                    associer.
                                </Text>
                                <Box textAlign={"center"} my={5}>
                                    <Button
                                        colorScheme="red"
                                        mx={"auto"}
                                        onClick={() => {
                                            setIsModalLink(false);
                                            navigate("/inner/cabinets/0/edit");
                                        }}
                                    >
                                        Ajouter un nouveau cabinet
                                    </Button>
                                </Box>
                            </>
                        )}
                        {cabinets.length > 0 && currentMedecin && (
                            <>
                                <Flex
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    gap={3}
                                >
                                    <Avatar
                                        name={`${currentMedecin.nom} ${currentMedecin.prenom}`}
                                        src={`${baseUrl}${currentMedecin.photo?.url}`}
                                        size={"lg"}
                                        bg={"#E2E8F0"}
                                    />
                                    <Text
                                        fontFamily={"var(--font-secondary)"}
                                        fontSize={"20px"}
                                        fontWeight={700}
                                    >
                                        Dr {currentMedecin.nom}{" "}
                                        {currentMedecin.prenom}
                                    </Text>
                                </Flex>
                                <Tabs
                                    isFitted
                                    mt={6}
                                    index={activeModalRelationTab}
                                    onChange={setActiveModalRelationTab}
                                >
                                    <TabList>
                                        <TabItemTitle
                                            numero={1}
                                            label="Cabinet & horaires d'injection"
                                        />
                                        <TabItemTitle
                                            numero={2}
                                            label="Pièces du RDV"
                                        />
                                        <TabItemTitle
                                            numero={3}
                                            label="Solution 
                                            de réservation"
                                        />
                                    </TabList>

                                    <TabPanels>
                                        <TabPanel>
                                            <ModalCabinetComponent />
                                        </TabPanel>
                                        <TabPanel>
                                            <ModalPieceRdvComponent />
                                        </TabPanel>
                                        <TabPanel>
                                            <ModalSolutionResaComponent />
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ModalLinkComponent;
