import { Box, Button, Heading, Stack } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import RoundedBoxComponent from "../components/RoundedBoxComponent";
import { isLogged } from "../helpers/session.helper";
import { TPage, getPage } from "../services/page.service";

const CgvPage = () => {
    const mountedRef = useRef(true);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState<TPage | null>(null);

    useEffect(() => {
        if (isLogged()) {
            navigate("/inner");
        } else {
            (async () => {
                setCurrentPage(await getPage(4));
            })();
        }

        return () => {
            mountedRef.current = false;
        };
    }, []);

    return (
        <>
            <style>
                {`
                    h2 {
                        font-weight: 700;
                        font-size: 32px;
                        margin-top: 20px;
                    }
                    
                    h3 {
                        font-weight: 700;
                        font-size: 26px;
                        margin-top: 20px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 26px;
                        margin-bottom: 1em;
                    }

                    .wysiwyg ul {
                        margin-left: 2em;
                        margin-top: 1em;
                        margin-bottom: 1em;
                    }
                `}
            </style>

            <RoundedBoxComponent centerVertical width="850px">
                {currentPage && (
                    <>
                        <Stack maxW={"850px"} mx={"auto"} px={"50px"}>
                            <Heading
                                as={"h1"}
                                textAlign={"center"}
                                textTransform={"uppercase"}
                                mt={"50px"}
                            >
                                {currentPage.titre}
                            </Heading>
                            <Box my={"30px"} className="wysiwyg">
                                <ReactMarkdown>
                                    {currentPage.contenu}
                                </ReactMarkdown>
                            </Box>
                        </Stack>
                    </>
                )}

                <Box>
                    <Button
                        size={"lg"}
                        w={"100%"}
                        colorScheme="black"
                        variant={"link"}
                        onClick={() => navigate("/login")}
                    >
                        Retour à l'accueil
                    </Button>
                </Box>
            </RoundedBoxComponent>
        </>
    );
};

export default CgvPage;
