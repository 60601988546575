import { TDepartement } from "../types/departement";
import { appRequest } from "./services";

export const getDepartementList = async () => {
    const currentRequest = await appRequest<{
        data: Array<{ id: number; attributes: TDepartement }>;
    }>({
        url: "departements?pagination[pageSize]=900",
    });

    return currentRequest.data.data
        .map((item) => {
            return {
                ...item.attributes,
                id: item.id,
            };
        })
        .sort((a, b) => {
            if (a.code < b.code) {
                return -1;
            }

            if (a.code > b.code) {
                return 1;
            }

            return 0;
        });
};
