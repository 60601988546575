import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, IconButton, Image, Text } from "@chakra-ui/react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { ProtectedContext } from "../../../providers/ProtectedProvider";
import { THoraire } from "../../../types/cabinet";
import FormsComponent from "../../FormComponent";

const HoraireItemComponent: FC<{
    form_name: string;
    currentForm: UseFormReturn<any>;
}> = ({ form_name, currentForm }) => {
    const mountedRef = useRef(true);
    const { currentCabinet } = useContext(ProtectedContext);
    const [tHoraire, setTHoraire] = useState<Array<THoraire>>([]);

    const tOptionHeure = () => {
        const tRes = [];
        for (let i = 0; i <= 23; i++) {
            let value = i.toString();

            if (i < 10) {
                value = `0${i}`;
            }

            tRes.push({
                key: value,
                label: `${value}h`,
            });
        }
        return tRes;
    };

    const tOptionMinute = () => {
        const tRes = [];
        for (let i = 0; i <= 59; i++) {
            let value = i.toString();

            if (i < 10) {
                value = `0${i}`;
            }

            tRes.push({
                key: value,
                label: `${value}min`,
            });
        }
        return tRes;
    };

    const addNewHoraire = () => {
        const newLine: THoraire = {
            heure_debut: "08",
            minute_debut: "00",
            heure_fin: "20",
            minute_fin: "00",
        };

        setTHoraire((prev) => [...prev, newLine]);
    };

    const removeHoraire = (key: number) => {
        setTHoraire((prev) => {
            const tRes = prev;
            delete tRes[key];
            return tRes.filter((item) => item !== null);
        });

        currentForm.unregister(`${form_name}.${key}.heure_debut`);
        currentForm.unregister(`${form_name}.${key}.minute_debut`);
        currentForm.unregister(`${form_name}.${key}.heure_fin`);
        currentForm.unregister(`${form_name}.${key}.minute_fin`);
    };

    useEffect(() => {
        if (currentCabinet) {
            const key = form_name as keyof typeof currentCabinet;
            currentForm.reset({
                [form_name]: currentCabinet[key],
            });

            setTHoraire(currentForm.getValues(form_name));
        }

        return () => {
            mountedRef.current = false;
        };
    }, [currentCabinet?.id]);

    return (
        <>
            {tHoraire.length === 0 && (
                <Text fontStyle={"italic"} color={"rgba(45, 55, 72, 0.50)"}>
                    Fermé
                </Text>
            )}

            {tHoraire.map((item, key) => (
                <Flex
                    key={key}
                    gap={3}
                    justifyContent={"center"}
                    alignItems={"center"}
                    my={3}
                >
                    <Text>De</Text>
                    <FormsComponent
                        form={currentForm}
                        name={`${form_name}.${key}.heure_debut`}
                        options={tOptionHeure()}
                        defaultValue={item.heure_debut}
                        type="select"
                    />
                    <FormsComponent
                        form={currentForm}
                        name={`${form_name}.${key}.minute_debut`}
                        options={tOptionMinute()}
                        defaultValue={item.minute_debut}
                        type="select"
                    />
                    <Text>à</Text>
                    <FormsComponent
                        form={currentForm}
                        name={`${form_name}.${key}.heure_fin`}
                        options={tOptionHeure()}
                        defaultValue={item.heure_fin}
                        type="select"
                    />
                    <FormsComponent
                        form={currentForm}
                        name={`${form_name}.${key}.minute_fin`}
                        options={tOptionMinute()}
                        defaultValue={item.minute_fin}
                        type="select"
                    />
                    <IconButton
                        variant={"outline"}
                        aria-label="Supprimer"
                        width={"48px"}
                        height={"48px"}
                        bg={"#FFFFFF"}
                        flexShrink={0}
                        icon={
                            <Image
                                src="/images/trash.svg"
                                alt={"Supprimer"}
                                width={"24px"}
                                height={"24px"}
                            />
                        }
                        onClick={() => removeHoraire(key)}
                    />
                </Flex>
            ))}
            <Box textAlign={"right"} mt={3}>
                <Button
                    leftIcon={<AddIcon color={"var(--color-red)"} />}
                    variant={"outline"}
                    onClick={() => addNewHoraire()}
                >
                    Ajouter un horaire
                </Button>
            </Box>
        </>
    );
};

export default HoraireItemComponent;
