import { SearchIcon } from "@chakra-ui/icons";
import {
    Checkbox,
    FormControl,
    FormHelperText,
    Input,
    InputGroup,
    InputRightElement,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Text,
    Textarea,
} from "@chakra-ui/react";
import { FC, PropsWithChildren, useEffect, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
type ContainerProps = {
    form: UseFormReturn<any>;
    name: string;
    label?: string;
    indication?: string;
    placeholder?: string;
    defaultValue?: number | string | null;
    required?: string | boolean | undefined;
    readonly?: boolean;
    options?: Array<{
        key: string | number | undefined;
        label: string | number;
    }>;
    message?: string;
    type:
        | "text"
        | "email"
        | "tel"
        | "number"
        | "date"
        | "password"
        | "checkbox"
        | "radio"
        | "select"
        | "search"
        | "textarea";
    min?: number;
    minLength?: number;
    maxLength?: number;
};

const ErrorMessage: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Text fontSize={"sm"} fontStyle={"italic"} color="var(--color-red)">
            {children}
        </Text>
    );
};

const LabelComponent: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Text fontSize={"16px"} fontWeight={700} mb={2}>
            {children}
        </Text>
    );
};

const FormsComponent: FC<ContainerProps> = (props: ContainerProps) => {
    const mountedRef = useRef(true);
    const name = props.name?.toString();
    const [selected, setSelected] = useState<string>("");

    useEffect(() => {
        setSelected(props.defaultValue?.toString() || "");
        return () => {
            mountedRef.current = false;
        };
    }, [props.defaultValue]);

    const formContent = () => {
        switch (props.type) {
            case "email":
                return (
                    <>
                        {props.label && (
                            <LabelComponent>
                                {props.label} {props.required && <sup>*</sup>}
                            </LabelComponent>
                        )}

                        <Input
                            type={props.type}
                            placeholder={props.placeholder}
                            readOnly={props.readonly}
                            autoComplete="off"
                            paddingInline={3}
                            height={"48px"}
                            borderColor={"#E2E8F0"}
                            _focusVisible={{
                                borderColor: "var(--color-red)",
                            }}
                            isInvalid={
                                !!props.form.formState.errors[props.name]
                            }
                            {...props.form.register(name, {
                                required: props.required,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Email invalide",
                                },
                            })}
                        />

                        {props.indication && (
                            <FormHelperText>{props.indication}</FormHelperText>
                        )}

                        {props.form.formState.errors[props.name] && (
                            <ErrorMessage>
                                {props.form.formState.errors[props.name]
                                    ?.message ? (
                                    <>
                                        {
                                            props.form.formState.errors[
                                                props.name
                                            ]?.message
                                        }
                                    </>
                                ) : (
                                    <>
                                        {props.message ||
                                            "Veuillez vérifier ce champ"}
                                    </>
                                )}
                            </ErrorMessage>
                        )}
                    </>
                );

            case "textarea":
                return (
                    <>
                        {props.label && (
                            <LabelComponent>
                                {props.label} {props.required && <sup>*</sup>}
                            </LabelComponent>
                        )}

                        <Textarea
                            placeholder={props.placeholder}
                            readOnly={props.readonly}
                            autoComplete="off"
                            paddingInline={3}
                            borderColor={"#E2E8F0"}
                            _focusVisible={{
                                borderColor: "var(--color-red)",
                            }}
                            rows={5}
                            {...props.form.register(name, {
                                required: props.required,
                            })}
                        />
                        {props.indication && (
                            <FormHelperText>{props.indication}</FormHelperText>
                        )}

                        {props.form.formState.errors[props.name] && (
                            <ErrorMessage>
                                {props.form.formState.errors[props.name]
                                    ?.message ? (
                                    <>
                                        {
                                            props.form.formState.errors[
                                                props.name
                                            ]?.message
                                        }
                                    </>
                                ) : (
                                    <>
                                        {props.message ||
                                            "Veuillez vérifier ce champ"}
                                    </>
                                )}
                            </ErrorMessage>
                        )}
                    </>
                );

            case "radio":
                return (
                    <>
                        {props.label && (
                            <LabelComponent>
                                {props.label} {props.required && <sup>*</sup>}
                            </LabelComponent>
                        )}
                        <RadioGroup value={selected} onChange={setSelected}>
                            <Stack direction="row" gap={6}>
                                {props.options &&
                                    props.options.map((item, index) => (
                                        <Radio
                                            key={index}
                                            colorScheme="red"
                                            size={"lg"}
                                            disabled={props.readonly}
                                            value={item.key?.toString()}
                                            {...props.form.register(name, {
                                                required: props.required,
                                            })}
                                        >
                                            {item.label}
                                        </Radio>
                                    ))}
                            </Stack>

                            {props.form.formState.errors[props.name] && (
                                <ErrorMessage>
                                    {props.form.formState.errors[props.name]
                                        ?.message ? (
                                        <>
                                            {
                                                props.form.formState.errors[
                                                    props.name
                                                ]?.message
                                            }
                                        </>
                                    ) : (
                                        <>
                                            {props.message ||
                                                "Veuillez vérifier ce champ"}
                                        </>
                                    )}
                                </ErrorMessage>
                            )}
                        </RadioGroup>
                        {props.indication && (
                            <FormHelperText>{props.indication}</FormHelperText>
                        )}
                    </>
                );

            case "checkbox":
                return (
                    <>
                        {props.indication && (
                            <FormHelperText>{props.indication}</FormHelperText>
                        )}

                        <Checkbox
                            value="1"
                            isDisabled={props.readonly}
                            {...props.form.register(name, {
                                required: props.required,
                            })}
                            size={"lg"}
                            colorScheme="red"
                            defaultChecked={props.defaultValue ? true : false}
                        >
                            {props.label}
                        </Checkbox>

                        {props.form.formState.errors[props.name] && (
                            <ErrorMessage>
                                {props.form.formState.errors[props.name]
                                    ?.message ? (
                                    <>
                                        {
                                            props.form.formState.errors[
                                                props.name
                                            ]?.message
                                        }
                                    </>
                                ) : (
                                    <>
                                        {props.message ||
                                            "Veuillez vérifier ce champ"}
                                    </>
                                )}
                            </ErrorMessage>
                        )}
                    </>
                );

            case "select":
                return (
                    <>
                        {props.label && (
                            <LabelComponent>
                                {props.label} {props.required && <sup>*</sup>}
                            </LabelComponent>
                        )}
                        {props.indication && (
                            <FormHelperText>{props.indication}</FormHelperText>
                        )}

                        <Select
                            placeholder={props.placeholder}
                            disabled={props.readonly}
                            style={{ paddingLeft: "12px" }}
                            height={"48px"}
                            borderColor={"#E2E8F0"}
                            _focusVisible={{
                                borderColor: "var(--color-red)",
                            }}
                            {...props.form.register(name, {
                                required: props.required,
                            })}
                            defaultValue={props.defaultValue || ""}
                        >
                            {props.options &&
                                props.options.map((item, index) => (
                                    <option key={index} value={item.key}>
                                        {item.label}
                                    </option>
                                ))}
                        </Select>

                        {props.form.formState.errors[props.name] && (
                            <ErrorMessage>
                                {props.form.formState.errors[props.name]
                                    ?.message ? (
                                    <>
                                        {
                                            props.form.formState.errors[
                                                props.name
                                            ]?.message
                                        }
                                    </>
                                ) : (
                                    <>
                                        {props.message ||
                                            "Veuillez vérifier ce champ"}
                                    </>
                                )}
                            </ErrorMessage>
                        )}
                    </>
                );

            case "search":
                return (
                    <>
                        {props.label && (
                            <LabelComponent>
                                {props.label} {props.required && <sup>*</sup>}
                            </LabelComponent>
                        )}
                        <InputGroup>
                            <Input
                                type="text"
                                placeholder={props.placeholder}
                                readOnly={props.readonly}
                                autoComplete="off"
                                paddingInline={3}
                                height={"48px"}
                                borderColor={"#E2E8F0"}
                                _focusVisible={{
                                    borderColor: "var(--color-red)",
                                }}
                                {...props.form.register(name, {
                                    required: props.required,
                                })}
                                min={props.min || undefined}
                                minLength={props.minLength || undefined}
                                maxLength={props.maxLength || undefined}
                            />
                            <InputRightElement height={"48px"} pr={2}>
                                <SearchIcon />
                            </InputRightElement>
                        </InputGroup>
                        {props.indication && (
                            <FormHelperText>{props.indication}</FormHelperText>
                        )}
                        {props.form.formState.errors[props.name] && (
                            <ErrorMessage>
                                {props.form.formState.errors[props.name]
                                    ?.message ? (
                                    <>
                                        {
                                            props.form.formState.errors[
                                                props.name
                                            ]?.message
                                        }
                                    </>
                                ) : (
                                    <>
                                        {props.message ||
                                            "Veuillez vérifier ce champ"}
                                    </>
                                )}
                            </ErrorMessage>
                        )}
                    </>
                );
            default:
                return (
                    <>
                        {props.label && (
                            <LabelComponent>
                                {props.label} {props.required && <sup>*</sup>}
                            </LabelComponent>
                        )}
                        <Input
                            type={props.type}
                            placeholder={props.placeholder}
                            readOnly={props.readonly}
                            autoComplete="off"
                            paddingInline={3}
                            height={"48px"}
                            borderColor={"#E2E8F0"}
                            _focusVisible={{
                                borderColor: "var(--color-red)",
                            }}
                            {...props.form.register(name, {
                                required: props.required,
                                valueAsNumber: props.type === "number",
                            })}
                            min={props.min || undefined}
                            minLength={props.minLength || undefined}
                            maxLength={props.maxLength || undefined}
                            defaultValue={props.defaultValue || ""}
                        />
                        {props.indication && (
                            <FormHelperText>{props.indication}</FormHelperText>
                        )}
                        {props.form.formState.errors[props.name] && (
                            <ErrorMessage>
                                {props.form.formState.errors[props.name]
                                    ?.message ? (
                                    <>
                                        {
                                            props.form.formState.errors[
                                                props.name
                                            ]?.message
                                        }
                                    </>
                                ) : (
                                    <>
                                        {props.message ||
                                            "Veuillez vérifier ce champ"}
                                    </>
                                )}
                            </ErrorMessage>
                        )}
                    </>
                );
        }
    };

    return <FormControl>{formContent()}</FormControl>;
};

export default FormsComponent;
