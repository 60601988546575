import {
    Box,
    Button,
    Card,
    CardBody,
    Flex,
    Image,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Stack,
    Text,
    useToast,
} from "@chakra-ui/react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import FormsComponent from "../../components/FormComponent";
import TitleComponent from "../../components/TitleComponent";
import MedecinsImageComponent from "../../components/medecin/MedecinsImageComponent";
import { AppContext } from "../../providers/AppProvider";
import { ProtectedContext } from "../../providers/ProtectedProvider";
import {
    createMedecin,
    getMedecin,
    updateMedecin,
} from "../../services/medecin.services";

type TForm = {
    id: number;
    uid: string;
    user_uid: string;
    civilite: string;
    nom: string;
    prenom: string;
    profession: string;
    keyword: string;
};

const MedecinFormPage: FC = () => {
    const mountedRef = useRef(true);
    const { user } = useContext(AppContext);
    const { currentMedecin, setCurrentMedecin, setIsModalLink } =
        useContext(ProtectedContext);
    const navigate = useNavigate();
    const toast = useToast();
    const [title, setTitle] = useState<string>("");
    const [btnLabel, setBtnLabel] = useState<string>("");
    const { id } = useParams<{ id: string }>();
    const currentForm = useForm<TForm>();
    const [isModalAddOpen, setIsModalAddOpen] = useState<boolean>(false);

    const linkMedecin = async (uid_medecin: string | undefined) => {
        setIsModalAddOpen(false);
        if (uid_medecin) {
            const reqMed = await getMedecin(uid_medecin);
            if (reqMed.length === 1) {
                setCurrentMedecin(reqMed[0]);
                setIsModalLink(true);
            }
        }
    };

    const onSubmit: SubmitHandler<TForm> = async (formData) => {
        formData.keyword = [formData.nom, formData.prenom]
            .join(" ")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

        let appReq;
        if (id === "0") {
            formData.user_uid = user.uid;
            formData.uid = uuid();
            appReq = await createMedecin(formData);
            if (appReq.data.attributes) {
                toast({
                    description: "Nouveau médecin bien ajouté.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });

                setIsModalAddOpen(true);
            }
        } else if (id) {
            appReq = await updateMedecin(formData);
            if (appReq.data.attributes) {
                toast({
                    description: "La fiche Médecin bien modifiée.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            }

            navigate(`/inner/medecins`);
        }
    };

    useEffect(() => {
        if (id && id !== "0") {
            setBtnLabel("Modifier la fiche médecin");
            (async () => {
                const req = await getMedecin(id);

                if (req.length !== 1) {
                    toast({
                        title: "Erreur",
                        description: "Le contenu demandé n'est pas trouvé.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                    navigate("/inner/cabinets");
                } else {
                    const defValue = req[0];
                    currentForm.reset({
                        id: defValue.id,
                        civilite: defValue.civilite,
                        nom: defValue.nom,
                        prenom: defValue.prenom,
                        profession: defValue.profession,
                    });
                    setCurrentMedecin(defValue);
                }
            })();
            setTitle("Modification de la fiche");
        } else {
            setTitle("Création d'une fiche médecin");
            setBtnLabel("Créer la fiche médecin");
            setCurrentMedecin(null);
        }

        return () => {
            setCurrentMedecin(null);
            mountedRef.current = false;
        };
    }, [id]);

    return (
        <>
            <TitleComponent title="Les médecins" />
            <Box w={"800px"} mx={"auto"} my={6}>
                <Card bg={"#FFFFFF"}>
                    <CardBody>
                        <Text
                            textAlign={"center"}
                            fontWeight={700}
                            fontSize={"22"}
                            mt={10}
                        >
                            {title}
                        </Text>
                        <form onSubmit={currentForm.handleSubmit(onSubmit)}>
                            <Stack
                                spacing={8}
                                direction={"column"}
                                my={5}
                                width={"600px"}
                                mx="auto"
                            >
                                {currentMedecin && <MedecinsImageComponent />}
                                <FormsComponent
                                    form={currentForm}
                                    name="civilite"
                                    label="Civilité"
                                    type="radio"
                                    defaultValue={currentForm.getValues(
                                        "civilite"
                                    )}
                                    options={[
                                        {
                                            key: "madame",
                                            label: "Madame",
                                        },
                                        {
                                            key: "monsieur",
                                            label: "Monsieur",
                                        },
                                    ]}
                                    required={true}
                                />

                                <FormsComponent
                                    form={currentForm}
                                    name="nom"
                                    label="Nom"
                                    placeholder="Nom"
                                    type="text"
                                    required
                                />

                                <FormsComponent
                                    form={currentForm}
                                    name="prenom"
                                    label="Prénom"
                                    placeholder="Prénom"
                                    type="text"
                                    required
                                />

                                <FormsComponent
                                    form={currentForm}
                                    name="profession"
                                    label="Profession"
                                    placeholder="Votre profession"
                                    type="textarea"
                                />

                                <Button
                                    type="submit"
                                    colorScheme="red"
                                    size={"lg"}
                                >
                                    {btnLabel}
                                </Button>
                            </Stack>
                        </form>
                    </CardBody>
                </Card>
                <Button
                    size={"xl"}
                    width={"100%"}
                    mt={5}
                    variant={"outline"}
                    isDisabled={id === "0"}
                    borderColor={"#999"}
                    bg={"#ffffff"}
                    onClick={() => linkMedecin(currentMedecin?.uid)}
                >
                    <Image width={"40px"} src="/images/link-add.svg" alt="" />
                    <Text
                        fontSize={"20px"}
                        fontWeight={600}
                        width={"350px"}
                        whiteSpace={"normal"}
                        py={4}
                    >
                        Associer ce médecin à mon cabinet et définir ses
                        horaires d'injection
                    </Text>
                </Button>
            </Box>

            <Modal
                isOpen={isModalAddOpen}
                onClose={() => setIsModalAddOpen(false)}
                size={"lg"}
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalBody p={10}>
                        <Text
                            align={"center"}
                            fontSize={"18px"}
                            lineHeight={"22px"}
                        >
                            Vous pouvez maintenant procéder à l'association du
                            médecin avec un cabinet
                        </Text>
                        <Flex gap={3} mt={8}>
                            <Button
                                type="button"
                                colorScheme="red"
                                size={"lg"}
                                w={"100%"}
                                onClick={() => {
                                    setIsModalAddOpen(false);
                                    navigate(`/inner/medecins`);
                                }}
                            >
                                J'ai compris
                            </Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default MedecinFormPage;
