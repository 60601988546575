import { TMedecin } from "../types/medecin";
import { appRequest } from "./services";

export const listAllMedecins = async (user_uid: string) => {
    const currentRequest = await appRequest<{
        data: Array<{ id: number; attributes: any }>;
    }>({
        url: `medecins?fields[0]=id&fields[1]=nom&fields[2]=prenom&fields[3]=uid&populate[photo]=*&filters[user_uid][$eq]=${user_uid}&pagination[pageSize]=100`,
    });

    return currentRequest.data.data.map((item) => ({
        ...item.attributes,
        id: item.id,
        photo: {
            id: item.attributes.photo.data?.id,
            filename: item.attributes.photo.data?.attributes.name,
            url: item.attributes.photo.data?.attributes.url,
        },
    }));
};

export const listMedecinsByName = async (keyword: string) => {
    const currentRequest = await appRequest<{
        data: Array<{ id: number; attributes: any }>;
    }>({
        data: {
            loading: false,
        },
        url: `medecins?fields[0]=id&fields[1]=nom&fields[2]=prenom&fields[3]=uid&populate[photo]=*&filters[$or][0][nom][$contains]=${keyword}&filters[$or][1][prenom][$contains]=${keyword}&filters[$or][2][keyword][$contains]=${keyword}`,
    });

    return currentRequest.data.data.map((item) => ({
        ...item.attributes,
        id: item.id,
        photo: item.attributes.photo.data
            ? {
                  id: item.attributes.photo.data.id,
                  filename: item.attributes.photo.data.attributes.name,
                  url: item.attributes.photo.data.attributes.url,
              }
            : undefined,
    }));
};

export const createMedecin = async (data: any) => {
    const currentRequest = await appRequest<{
        data: { id: number; attributes: TMedecin };
    }>({
        url: "medecins?populate=*",
        method: "POST",
        data: { data },
    });

    return currentRequest.data;
};

export const updateMedecin = async (data: any) => {
    const currentRequest = await appRequest<{
        data: { id: number; attributes: TMedecin };
    }>({
        url: `medecins/${data.id}?populate=*`,
        method: "PUT",
        data: { data },
    });

    return currentRequest.data;
};

export const getMedecin = async (uid: string) => {
    const currentRequest = await appRequest<{
        data: Array<{ id: number; attributes: any }>;
    }>({
        url: `medecins?populate=*&filters[uid][$eq]=${uid}`,
    });

    return currentRequest.data.data.map((item) => {
        return {
            id: item.id,
            uid: item.attributes.uid,
            nom: item.attributes.nom,
            prenom: item.attributes.prenom,
            civilite: item.attributes.civilite,
            profession: item.attributes.profession,
            keyword: item.attributes.keyword,
            photo: {
                id: item.attributes.photo.data?.id,
                filename: item.attributes.photo.data?.attributes.name,
                url: item.attributes.photo.data?.attributes.url,
            },
        } as TMedecin;
    });
};
