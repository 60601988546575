import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Alert,
    AlertIcon,
    Box,
    Button,
    Card,
    CardBody,
    Flex,
    Image,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useToast,
} from "@chakra-ui/react";
import {
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import ModalLinkComponent from "../components/modal/ModalLinkComponent";
import { logoutUser } from "../helpers/session.helper";
import { getMyCabinetsList } from "../services/cabinet.services";
import { getDepartementList } from "../services/departement.services";
import { TCabinet } from "../types/cabinet";
import { TDepartement } from "../types/departement";
import { TMedecin } from "../types/medecin";
import { TRelationMedecin } from "../types/relation";
import { AppContext } from "./AppProvider";

type TProtected = {
    currentCabinet: TCabinet | null;
    cabinets: Array<TCabinet>;
    setCurrentCabinet: Dispatch<SetStateAction<TCabinet | null>>;
    setCabinets: Dispatch<SetStateAction<TCabinet[]>>;
    departements: Array<TDepartement>;
    loadDepartements: () => void;
    currentMedecin: TMedecin | null;
    setCurrentMedecin: Dispatch<SetStateAction<TMedecin | null>>;
    isModalLink: boolean;
    setIsModalLink: Dispatch<SetStateAction<boolean>>;
    currentRelations: TRelationMedecin | null;
    setCurrentRelations: Dispatch<SetStateAction<TRelationMedecin | null>>;
    activeModalRelationTab: number;
    setActiveModalRelationTab: Dispatch<SetStateAction<number>>;
    activeTabCabinet: number;
    setActiveTabCabinet: Dispatch<SetStateAction<number>>;
};

type TProtectedProvider = PropsWithChildren<{}>;

const defaultState: TProtected = {
    currentCabinet: null,
    cabinets: [],
    setCurrentCabinet: () => {},
    setCabinets: () => {},
    departements: [],
    loadDepartements: () => {},
    currentMedecin: null,
    setCurrentMedecin: () => {},
    isModalLink: false,
    setIsModalLink: () => {},
    currentRelations: null,
    setCurrentRelations: () => {},
    activeModalRelationTab: 0,
    setActiveModalRelationTab: () => {},
    activeTabCabinet: 0,
    setActiveTabCabinet: () => {},
};

export const ProtectedContext = createContext<TProtected>(defaultState);

export const ProtectedProvider: FC<TProtectedProvider> = ({ children }) => {
    const mountedRef = useRef(true);
    const navigate = useNavigate();
    const { user } = useContext(AppContext);
    const location = useLocation();
    const toast = useToast();
    const sidebar_width = "300px";
    const [cabinets, setCabinets] = useState<Array<TCabinet>>([]);
    const [departements, setDepartements] = useState<Array<TDepartement>>([]);
    const [currentCabinet, setCurrentCabinet] = useState<TCabinet | null>(
        defaultState.currentCabinet
    );
    const [currentMedecin, setCurrentMedecin] = useState<TMedecin | null>(
        defaultState.currentMedecin
    );
    const [isModalLink, setIsModalLink] = useState<boolean>(
        defaultState.isModalLink
    );
    const [activeModalRelationTab, setActiveModalRelationTab] =
        useState<number>(defaultState.activeModalRelationTab);
    const [currentRelations, setCurrentRelations] =
        useState<TRelationMedecin | null>(defaultState.currentRelations);
    const [isLogoutModal, setIsLogoutModal] = useState<boolean>(false);
    const [activeTabCabinet, setActiveTabCabinet] = useState<number>(
        defaultState.activeTabCabinet
    );

    const AccordionHeader: FC<{
        title: string;
        url: string;
        hasIcon?: boolean;
    }> = ({ title, url, hasIcon = true }) => {
        const navigate = useNavigate();

        return (
            <h2>
                <AccordionButton
                    _expanded={{
                        bg: "#444444",
                        color: "#FFFFFF",
                    }}
                    height={"56px"}
                    onClick={() => navigate(`${url}`)}
                >
                    <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontSize={"16px"}
                        fontWeight={700}
                        textTransform={"uppercase"}
                    >
                        {title}
                    </Box>
                    {hasIcon && <AccordionIcon />}
                </AccordionButton>
            </h2>
        );
    };

    const isMatchPath = (uid: string) => {
        const matchDetails = matchPath(
            `/inner/cabinets/${uid}`,
            location.pathname
        );
        const matchEdit = matchPath(
            `/inner/cabinets/${uid}/edit`,
            location.pathname
        );

        return matchDetails || matchEdit;
    };

    const CabinetListItem: FC<{ label: string; uid: string }> = ({
        label,
        uid,
    }) => {
        return (
            <Flex
                padding={"16px 24px"}
                direction={"row"}
                borderBottom={"1px #E2E8F0 solid"}
                minH={"56px"}
                alignItems={"flex-start"}
                gap={2}
                role="button"
                bg={isMatchPath(uid) ? "#F8F1EF" : ""}
                onClick={() => navigate(`/inner/cabinets/${uid}`)}
            >
                <Image src="/images/icon-house.svg" mt={1} />
                <Text fontSize={"18px"} fontWeight={700} lineHeight={"24px"}>
                    {label}
                </Text>
            </Flex>
        );
    };

    const loadDepartements = async () => {
        setDepartements(await getDepartementList());
    };

    const loadMyCabinetsList = async () => {
        const reqCabinet = await getMyCabinetsList(user.uid);
        setCabinets(reqCabinet);
    };

    const logoutAction = () => {
        logoutUser();
        setCurrentCabinet(null);
        setCurrentMedecin(null);
        setCurrentRelations(null);
        navigate("/login");
        toast({
            title: "Déconnexion",
            description: "Vous êtes maintenant déconnecté.",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    useEffect(() => {
        (async () => loadMyCabinetsList())();

        return () => {
            mountedRef.current = false;
        };
    }, [user.id, currentCabinet?.nom]);

    return (
        <>
            <ProtectedContext.Provider
                value={{
                    cabinets,
                    setCabinets,
                    departements,
                    loadDepartements,
                    currentCabinet,
                    setCurrentCabinet,
                    currentMedecin,
                    setCurrentMedecin,
                    isModalLink,
                    setIsModalLink,
                    currentRelations,
                    setCurrentRelations,
                    activeModalRelationTab,
                    setActiveModalRelationTab,
                    activeTabCabinet,
                    setActiveTabCabinet,
                }}
            >
                <Box
                    width={sidebar_width}
                    pos={"fixed"}
                    top={0}
                    left={0}
                    bottom={0}
                    bg={"#FFFFFF"}
                >
                    <Box padding={"40px 40px 30px"}>
                        <Image
                            src="/images/logo.svg"
                            alt=""
                            width={"236px"}
                            mx={"auto"}
                        />
                    </Box>
                    <Box
                        position={"absolute"}
                        top={"173px"}
                        left={0}
                        right={0}
                        bottom={"135px"}
                        overflowY={"scroll"}
                    >
                        <Accordion defaultIndex={[0]}>
                            <AccordionItem>
                                <AccordionHeader
                                    title="Cabinets"
                                    url="cabinets"
                                />
                                <AccordionPanel p={0}>
                                    {cabinets.length === 0 && (
                                        <Card>
                                            <CardBody>
                                                <Alert status="info">
                                                    <AlertIcon />
                                                    Aucun cabinet n'est encore
                                                    renseigné
                                                </Alert>
                                            </CardBody>
                                        </Card>
                                    )}
                                    {cabinets.map((item, key) => (
                                        <CabinetListItem
                                            key={key}
                                            label={item.nom}
                                            uid={item.uid}
                                        />
                                    ))}
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader
                                    title="Médecins"
                                    url="medecins"
                                    hasIcon={false}
                                />
                            </AccordionItem>
                        </Accordion>
                    </Box>
                    <Box
                        position={"absolute"}
                        bottom={0}
                        left={0}
                        right={0}
                        height={"135px"}
                        padding={"30px"}
                        borderTop={"2px solid #E2E8F0"}
                    >
                        <Text
                            textAlign={"center"}
                            fontSize={"16px"}
                            fontFamily={"var(--font-secondary)"}
                            fontWeight={700}
                            lineHeight={"normal"}
                        >
                            Connecté en tant que {user.prenom} {user.nom} !
                        </Text>
                        <Text
                            role="button"
                            textAlign={"center"}
                            mt={2}
                            fontSize={"14px"}
                            textDecoration={"underline"}
                            onClick={() => setIsLogoutModal(true)}
                        >
                            Déconnexion
                        </Text>
                    </Box>
                </Box>
                <Box
                    as={"main"}
                    position={"absolute"}
                    top={0}
                    left={sidebar_width}
                    right={0}
                    bottom={0}
                    overflow={"scroll"}
                >
                    {children}
                </Box>

                <ModalLinkComponent />

                <Modal
                    isOpen={isLogoutModal}
                    onClose={() => setIsLogoutModal(false)}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Déconnexion</ModalHeader>
                        <ModalBody>
                            Voulez-vous vraiment vous déconnecter ?
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                variant="outline"
                                mr={3}
                                onClick={() => setIsLogoutModal(false)}
                            >
                                Annuler
                            </Button>
                            <Button
                                colorScheme={"red"}
                                onClick={() => logoutAction()}
                            >
                                Se déconnecter
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </ProtectedContext.Provider>
        </>
    );
};
