import { appRequest } from "./services";

type TAddParam = { refId: string; files: File; ref: string; field: string };

export const addImage = async ({ refId, files, ref, field }: TAddParam) => {
    const formData = new FormData();
    formData.append("files", files);
    formData.append("ref", ref);
    formData.append("refId", refId);
    formData.append("field", field);

    const currentRequest = await appRequest<
        Array<{ url: string; id: number; name: string }>
    >({
        url: "upload",
        method: "POST",
        contentType: "multipart/form-data",
        data: formData,
    });

    if (currentRequest.data.length === 1) {
        return currentRequest.data[0];
    } else {
        return { url: "", id: 0, name: "" };
    }
};

export const deleteMedia = async (fileId: number) => {
    const currentRequest = await appRequest<{ id: number }>({
        url: `upload/files/${fileId}`,
        method: "DELETE",
    });

    return currentRequest.data;
};
