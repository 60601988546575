import {
    Card,
    CardBody,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useToast,
} from "@chakra-ui/react";
import { FC, useContext, useEffect, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import TitleComponent from "../../components/TitleComponent";
import HorairesComponent from "../../components/cabinet/forms/HorairesComponent";
import InfoGenComponent from "../../components/cabinet/forms/InfoGenComponent";
import MedecinsListComponent from "../../components/medecin/MedecinsListComponent";
import { AppContext } from "../../providers/AppProvider";
import { ProtectedContext } from "../../providers/ProtectedProvider";
import { getMyCabinetDetails } from "../../services/cabinet.services";
import InfoGenDetailsComponent from "../../components/cabinet/details/InfoGenDetailsComponent";
import HorairesDetailsComponent from "../../components/cabinet/details/HorairesDetailsComponent";
import InfoGenImagesComponent from "../../components/cabinet/forms/InfoGenImagesComponent";

const CabinetFormPage: FC<{ edit?: boolean }> = ({ edit }) => {
    const mountedRef = useRef(true);
    const { user } = useContext(AppContext);
    const { currentCabinet, setCurrentCabinet, setActiveTabCabinet, activeTabCabinet } =
        useContext(ProtectedContext);
    const { id } = useParams<{ id: string }>();
    const toast = useToast();
    const navigate = useNavigate();
    const tTabs = [
        { disabled: false, label: "Informations générales", isVisible: true },
        { disabled: id === "0", label: "Horaires", isVisible: true },
        { disabled: id === "0", label: "Médecins reliés", isVisible: true },
        { disabled: id === "0", label: "Médias", isVisible: edit },
    ];

    const getTitle = useMemo(() => {
        if (!currentCabinet) {
            return "Nouveau cabinet";
        }

        return currentCabinet.nom;
    }, [currentCabinet?.nom]);

    useEffect(() => {
        if (id && id !== "0") {
            (async () => {
                const getCurrent = await getMyCabinetDetails(user.uid, id);

                if (getCurrent.length !== 1) {
                    toast({
                        title: "Erreur",
                        description: "Le contenu demandé n'est pas disponible",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                    navigate("/inner/cabinets");
                } else {
                    setCurrentCabinet(getCurrent[0]);
                }
            })();
        }

        return () => {
            // Reset current cabinet on unload
            if (id === "0") {
                setCurrentCabinet(null);
            }
            mountedRef.current = false;
        };
    }, [id]);

    return (
        <>
            <TitleComponent title={getTitle} />
            <Card bg={"#FFFFFF"} w={"800px"} mx={"auto"} my={6}>
                <CardBody p={0}>
                    <Tabs
                        isFitted
                        index={activeTabCabinet}
                        variant="unstyled"
                        pos={"relative"}
                    >
                        <TabList
                            mb="1em"
                            pos={"sticky"}
                            top={0}
                            height={"52px"}
                            zIndex={10}
                        >
                            {tTabs
                                .filter((item) => item.isVisible)
                                .map((item, key) => (
                                    <Tab
                                        key={key}
                                        bg={"#444444"}
                                        color={"#FFFFFF"}
                                        fontSize={"16px"}
                                        fontWeight={500}
                                        height={"52px"}
                                        px={1}
                                        _selected={{ bg: "var(--color-red)" }}
                                        isDisabled={item.disabled}
                                        borderTopLeftRadius={
                                            key === 0 ? "6px" : 0
                                        }
                                        borderTopRightRadius={
                                            key === tTabs.length - 1 ? "6px" : 0
                                        }
                                        onClick={() => setActiveTabCabinet(key)}
                                    >
                                        {item.label}
                                    </Tab>
                                ))}
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                {edit ? (
                                    <InfoGenComponent />
                                ) : (
                                    <InfoGenDetailsComponent />
                                )}
                            </TabPanel>
                            <TabPanel>
                                {edit && currentCabinet?.id ? (
                                    <HorairesComponent />
                                ) : (
                                    <HorairesDetailsComponent />
                                )}
                            </TabPanel>
                            <TabPanel>
                                {currentCabinet?.id && (
                                    <MedecinsListComponent />
                                )}
                            </TabPanel>
                            {edit && (
                                <TabPanel>
                                    {currentCabinet && (
                                        <InfoGenImagesComponent />
                                    )}
                                </TabPanel>
                            )}
                        </TabPanels>
                    </Tabs>
                </CardBody>
            </Card>
        </>
    );
};

export default CabinetFormPage;
