import { TRegister } from "../types/register";
import { TUser } from "../types/user";
import { appRequest } from "./services";

export const getCurrentUser = async () => {
    const userRequest = await appRequest<TUser>({
        url: "users/me?populate=*",
    });

    return userRequest.data;
};

export const authUser = async (email: string, password: string) => {
    const userRequest = await appRequest<TUser>({
        url: "auth/local",
        method: "POST",
        data: {
            identifier: email,
            password,
        },
    });

    return userRequest.data;
};

export const registerUser = async (data: any) => {
    const userRequest = await appRequest<{ user: TUser }>({
        url: "auth/local/register",
        method: "POST",
        data,
    });

    return userRequest.data;
};

export const forgotPassword = async (email: string) => {
    const userRequest = await appRequest<{ ok: boolean }>({
        url: "auth/forgot-password",
        method: "POST",
        data: {
            email,
        },
    });

    return userRequest.data;
};

export const resetPassword = async (
    code: string,
    password: string,
    passwordConfirmation: string
) => {
    const userRequest = await appRequest<{ ok: boolean }>({
        url: "auth/reset-password",
        method: "POST",
        data: {
            code,
            password,
            passwordConfirmation,
        },
    });

    return userRequest.data;
};

const sendEmail = async (to: string, subject: string, message: string) => {
    const emailRequest = await appRequest<{ ok: boolean }>({
        url: "email",
        method: "POST",
        data: {
            from: "contact@ivtmobility.com",
            to,
            subject,
            html: message,
        },
    });

    return emailRequest.data;
};

export const sendAdminEmail = async (user: TRegister, id: number) => {
    const message = `
    <p>Un nouvel utilisateur souhaite rejoindre la plateforme IVT Mobility.</p>
    <p>&nbsp;</p>
    <p>Voici ses coordonnées :</p>
    <p>Civilité: ${user.civilite === "monsieur" ? "Mosieur" : "Madame"}</p>
    <p>Nom: ${user.nom}</p>
    <p>Prenom: ${user.prenom}</p>
    <p>Poste: ${user.poste}</p>
    <p>Nom du Cabinet: ${user.nom_cabinet}</p>
    <p>Email: ${user.email}</p>
    <p><a href="https://api.ivtmobility.com/admin/content-manager/collectionType/plugin::users-permissions.user/${id}">Consulter la fiche</a></p>
    `;
    const reqSend = await sendEmail(
        "manager@retinegallien.com",
        `${user.nom} ${user.prenom} souhaite rejoindre la plateforme IVTMobility`,
        message
    );

    return reqSend;
};
