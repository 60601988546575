import {
    Alert,
    AlertDescription,
    Box,
    Button,
    Image,
    Stack,
    Text,
    useToast,
    AlertIcon,
    Flex,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import FormsComponent from "../components/FormComponent";
import RoundedBoxComponent from "../components/RoundedBoxComponent";
import { isLogged } from "../helpers/session.helper";
import { registerUser, sendAdminEmail } from "../services/user.services";
import { TRegister } from "../types/register";

const RegisterPage = () => {
    const mountedRef = useRef(true);
    const navigate = useNavigate();
    const toast = useToast();
    const registerForm = useForm<TRegister>();
    const [isRegisterOk, setIsregisterOk] = useState<boolean>(false);

    const onSubmit: SubmitHandler<TRegister> = async (formData) => {
        const register = await registerUser({
            username: formData.email,
            email: formData.email,
            password: formData.password,
            civilite: formData.civilite,
            poste: formData.poste,
            nom: formData.nom,
            prenom: formData.prenom,
            uid: uuid(),
        });
        if (register.error) {
            if (
                register.error.message === "Email or Username are already taken"
            ) {
                toast({
                    title: "Erreur",
                    description:
                        "Cet email est déjà associé à un autre compte.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else if (register.user.email) {
            await sendAdminEmail(formData, register.user.id);
            setIsregisterOk(true);
        }
    };

    useEffect(() => {
        if (isLogged()) {
            navigate("/inner");
        }

        return () => {
            mountedRef.current = false;
        };
    }, []);

    return (
        <RoundedBoxComponent width="800px">
            <Box>
                <Image
                    src="/images/logo.svg"
                    alt=""
                    width={"236px"}
                    mx={"auto"}
                    mb={8}
                />
            </Box>
            {isRegisterOk && (
                <Alert status="success" alignItems={"flex-start"}>
                    <AlertIcon mr={5} mt={2} />
                    <AlertDescription>
                        <Text fontWeight={700}>
                            La demande d’inscription a bien été envoyée
                        </Text>
                        <Box>
                            Nous vous remercions pour votre demande
                            d'inscription. Celle-ci est à l'étude et nous
                            reviendrons vers vous par email lorsque les
                            informations transmises auront été contrôlées.
                        </Box>
                    </AlertDescription>
                </Alert>
            )}
            {!isRegisterOk && (
                <form onSubmit={registerForm.handleSubmit(onSubmit)}>
                    <Stack spacing={8} direction={"column"} my={5}>
                        <FormsComponent
                            form={registerForm}
                            name="civilite"
                            label="Civilité"
                            type="radio"
                            options={[
                                {
                                    key: "madame",
                                    label: "Madame",
                                },
                                {
                                    key: "monsieur",
                                    label: "Monsieur",
                                },
                            ]}
                            required={true}
                        />

                        <FormsComponent
                            form={registerForm}
                            name="nom"
                            label="Nom"
                            placeholder="Nom"
                            type="text"
                            required={true}
                        />

                        <FormsComponent
                            form={registerForm}
                            name="prenom"
                            label="Prénom"
                            placeholder="Prénom"
                            type="text"
                            required={true}
                        />

                        <FormsComponent
                            form={registerForm}
                            name="poste"
                            label="Poste"
                            placeholder="Poste"
                            type="text"
                            required={true}
                        />

                        <FormsComponent
                            form={registerForm}
                            name="nom_cabinet"
                            label="Nom du cabinet"
                            placeholder="Nom du cabinet"
                            type="text"
                            required={true}
                        />

                        <FormsComponent
                            form={registerForm}
                            name="email"
                            label="Email"
                            placeholder="Email"
                            type="email"
                            required={true}
                        />
                        <FormsComponent
                            form={registerForm}
                            name="password"
                            label="Mot de passe"
                            placeholder="Mot de passe"
                            type="password"
                            required={true}
                        />
                        <Flex pos={"relative"}>
                            <Box mr={2}>
                                <FormsComponent
                                    form={registerForm}
                                    name="cgv"
                                    type="checkbox"
                                    label="J'accepte les"
                                    required={true}
                                />
                            </Box>
                            <Box flex={1} pos={"absolute"} left={"145px"} top={0} fontSize={"18px"}>
                                {" "}
                                <Link
                                    to="/conditions-generales-d-utilisation"
                                    style={{color: "var(--color-red)", textDecoration: "underline"}}
                                >
                                    conditions générales de vente et d'utilisation
                                </Link>
                            </Box>
                        </Flex>

                        <Box textAlign={"center"}>
                            <Button
                                type="submit"
                                colorScheme={"red"}
                                size={"lg"}
                                w={"100%"}
                            >
                                Valider la demande d'inscription
                            </Button>
                        </Box>
                        <Box textAlign={"center"} fontSize={"18px"}>
                            Déjà inscrit ? connectez-vous{" "}
                            <Link to={"/login"}>
                                <Box
                                    as="span"
                                    color={"var(--color-red)"}
                                    fontWeight={700}
                                    transition={"all .25s ease-in-out"}
                                    _hover={{ textDecor: "underline" }}
                                >
                                    en cliquant ici
                                </Box>
                            </Link>
                        </Box>
                    </Stack>
                </form>
            )}
        </RoundedBoxComponent>
    );
};

export default RegisterPage;
