import { Box, Button, Image, Stack, useToast } from "@chakra-ui/react";
import { FC, useContext, useEffect, useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import FormsComponent from "../components/FormComponent";
import RoundedBoxComponent from "../components/RoundedBoxComponent";
import { isLogged, logoutUser, setLogged } from "../helpers/session.helper";
import { AppContext } from "../providers/AppProvider";
import { authUser, getCurrentUser } from "../services/user.services";
import { TLogin } from "../types/login";

const LoginPage: FC = () => {
    const { setUser } = useContext(AppContext);
    const mountedRef = useRef(true);
    const navigate = useNavigate();
    const loginForm = useForm<TLogin>();
    const toast = useToast();

    const onSubmit: SubmitHandler<TLogin> = async (formData) => {
        const { error, jwt, user } = await authUser(
            formData.email,
            formData.password
        );

        if (error && error.status === 400) {
            toast({
                title: "Se connecter",
                description:
                    "Erreur d'authentification. Email et/ou Mot de passe incorrect.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (jwt && user) {
            if (user.blocked) {
                toast({
                    title: "Se connecter",
                    description:
                        "Votre compte a été suspendu. Veuillez contacter l'administrateur.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });

                return;
            }

            if (!user.confirmed) {
                toast({
                    title: "Mon compte",
                    description: "Votre est en cours de validation.",
                    status: "info",
                    duration: 9000,
                    isClosable: true,
                });

                return;
            }

            setLogged(jwt);
            const currentUser = await getCurrentUser();

            if (currentUser.role.id === 3) {
                toast({
                    title: "Mon compte",
                    description:
                        "Votre est en cours de validation par l'Administrateur. Vous recevrez un email de confirmation une fois validé.",
                    status: "info",
                    duration: 9000,
                    isClosable: true,
                });
                logoutUser();
                return;
            }

            toast({
                title: "Se connecter",
                description: "Bienvenue, vous êtes maintenant connecté.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            setLogged(jwt);
            setUser(user);
            navigate("/inner");
        }
    };

    useEffect(() => {
        if (isLogged()) {
            navigate("/inner");
        }

        return () => {
            mountedRef.current = false;
        };
    }, []);

    return (
        <RoundedBoxComponent centerVertical>
            <Box>
                <Image
                    src="/images/logo.svg"
                    alt=""
                    width={"236px"}
                    mx={"auto"}
                    mb={8}
                />
            </Box>
            <form onSubmit={loginForm.handleSubmit(onSubmit)}>
                <Stack spacing={8} direction={"column"} my={5}>
                    <FormsComponent
                        form={loginForm}
                        name="email"
                        label="Email"
                        placeholder="Email"
                        type="email"
                        required={true}
                    />

                    <FormsComponent
                        form={loginForm}
                        name="password"
                        label="Mot de passe"
                        placeholder="Mot de passe"
                        type="password"
                        required={true}
                    />
                    <Box textAlign={"right"} fontSize={"14px"}>
                        <Link to={"/reset-password"}>
                            <Box
                                as="span"
                                color={"#000000"}
                                transition={"all .25s ease-in-out"}
                                _hover={{ textDecor: "underline" }}
                            >
                                Réinitialiser mon mot de passe
                            </Box>
                        </Link>
                    </Box>
                    <Box textAlign={"center"}>
                        <Button
                            type="submit"
                            colorScheme={"red"}
                            size={"lg"}
                            w={"100%"}
                        >
                            Se connecter
                        </Button>
                    </Box>
                    <Box textAlign={"center"} fontSize={"18px"}>
                        Pas de compte sur la plateforme ?{" "}
                        <Link to={"/register"}>
                            <Box
                                as="span"
                                color={"var(--color-red)"}
                                fontWeight={700}
                                transition={"all .25s ease-in-out"}
                                _hover={{ textDecor: "underline" }}
                            >
                                Inscrivez-vous
                            </Box>
                        </Link>
                    </Box>
                </Stack>
            </form>
        </RoundedBoxComponent>
    );
};

export default LoginPage;
