import { Box, Button, Stack, Text, useToast } from "@chakra-ui/react";
import { FC, useContext, useEffect, useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ProtectedContext } from "../../../providers/ProtectedProvider";
import { updateCabinet } from "../../../services/cabinet.services";
import FormsComponent from "../../FormComponent";
import HoraireItemComponent from "./HoraireItemComponent";

type TForm = {
    [key: string]: any;
};

type THoraires = {
    [key: string]: {
        label: string;
        name: string;
    };
};

const HorairesComponent: FC = () => {
    const mountedRef = useRef(true);
    const toast = useToast();
    const currentForm = useForm<TForm>();
    const { currentCabinet, setActiveTabCabinet } = useContext(ProtectedContext);
    const tHoraires: THoraires = {
        horaires_lundi: {
            label: "Lundi",
            name: "horaires_lundi",
        },
        horaires_mardi: {
            label: "Mardi",
            name: "horaires_mardi",
        },
        horaires_mercredi: {
            label: "Mercredi",
            name: "horaires_mercredi",
        },
        horaires_jeudi: {
            label: "Jeudi",
            name: "horaires_jeudi",
        },
        horaires_vendredi: {
            label: "Vendredi",
            name: "horaires_vendredi",
        },
        horaires_samedi: {
            label: "Samedi",
            name: "horaires_samedi",
        },
        horaires_dimanche: {
            label: "Dimanche",
            name: "horaires_dimanche",
        },
    };

    const onSubmit: SubmitHandler<TForm> = async (formData) => {
        const oRes: any = {};
        Object.keys(tHoraires).forEach((name) => {
            oRes[name] = formData[name] || [];
        });

        const appReq = await updateCabinet({
            ...oRes,
            horaires_specifiques: formData.horaires_specifiques,
            id: currentCabinet?.id,
        });

        if (appReq.data.attributes) {
            toast({
                description: "Horaires bien enregistrés.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            setActiveTabCabinet(2);
        }
    };

    useEffect(() => {
        currentForm.reset({
            horaires_specifiques: currentCabinet?.horaires_specifiques,
        });

        return () => {
            mountedRef.current = false;
        };
    }, [currentCabinet?.id]);

    return (
        <>
            <Text textAlign={"center"} fontWeight={700} fontSize={"22"}>
                Horaires d'accueil
            </Text>
            <form onSubmit={currentForm.handleSubmit(onSubmit)}>
                <Stack
                    spacing={8}
                    direction={"column"}
                    my={5}
                    width={"600px"}
                    mx="auto"
                >
                    {Object.keys(tHoraires).map((item, key) => (
                        <Box key={key}>
                            <Text fontSize={"16px"} fontWeight={700} mb={2}>
                                {tHoraires[item].label}
                            </Text>
                            <HoraireItemComponent
                                form_name={item}
                                currentForm={currentForm}
                            />
                        </Box>
                    ))}

                    <FormsComponent
                        form={currentForm}
                        type="textarea"
                        label="Fermetures ou horaires spécifiques"
                        placeholder="Ex: Fermé le 25/12 et 01/01"
                        name="horaires_specifiques"
                    />

                    <Button type="submit" colorScheme="red" size={"lg"}>
                        Enregistrer les horaires
                    </Button>
                </Stack>
            </form>
        </>
    );
};

export default HorairesComponent;
