import { Box, Stack } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";
type TProps = PropsWithChildren<{
    centerVertical?: boolean;
    width?: string;
}>;
const RoundedBoxComponent: FC<TProps> = ({ children, centerVertical, width }) => {
    return (
        <Stack
            direction={"column"}
            minH={"100vh"}
            alignItems={"center"}
            justifyContent={centerVertical ? "center" : "flex-start"}
        >
            <Box
                w={width || "600px"}
                mx={"auto"}
                borderRadius={"10px"}
                my={8}
                bg={"#ffffff"}
                p={"36px 60px 60px"}
                boxShadow="0px 6px 10px 0px rgba(0, 0, 0, 0.10)"
                pos={"relative"}
            >
                {children}
            </Box>
        </Stack>
    );
};

export default RoundedBoxComponent;
