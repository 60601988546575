import { AddIcon } from "@chakra-ui/icons";
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Flex,
    Image,
    Table,
    Tbody,
    Td,
    Text,
    Tr,
} from "@chakra-ui/react";
import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import RoundedBoxComponent from "../../components/RoundedBoxComponent";
import TitleComponent from "../../components/TitleComponent";
import { ProtectedContext } from "../../providers/ProtectedProvider";

const CabinetsPage: FC = () => {
    const navigate = useNavigate();
    const { cabinets } = useContext(ProtectedContext);

    const addNewCabinet = () => {
        navigate("/inner/cabinets/0/edit");
    };

    return (
        <>
            <TitleComponent title="Cabinets" />
            <RoundedBoxComponent width="800px">
                <Text
                    as={"h1"}
                    textAlign={"center"}
                    fontWeight={700}
                    fontSize={"22px"}
                    mb={5}
                >
                    Retrouvez ici votre(vos) cabinet(s)
                </Text>
                {cabinets.length === 0 && (
                    <Alert status="info">
                        <AlertIcon />
                        Aucun cabinet n'est encore renseigné
                    </Alert>
                )}

                {cabinets.length > 0 && (
                    <Table variant="simple">
                        <Tbody>
                            {cabinets.map((item, key) => (
                                <Tr key={key}>
                                    <Td>
                                        <Flex alignItems={"center"} gap={2}>
                                            <Image src="/images/icon-house.svg" />
                                            <Text
                                                fontSize={"18px"}
                                                fontWeight={700}
                                            >
                                                {item.nom}
                                            </Text>
                                        </Flex>
                                    </Td>
                                    <Td w={"100px"} p={1}>
                                        <Button
                                            variant={"outline"}
                                            size={"sm"}
                                            colorScheme="dark"
                                            onClick={() =>
                                                navigate(
                                                    `/inner/cabinets/${item.uid}/edit`
                                                )
                                            }
                                        >
                                            Modifier
                                        </Button>
                                    </Td>
                                    <Td w={"100px"} p={1}>
                                        <Button
                                            colorScheme="red"
                                            size={"sm"}
                                            onClick={() =>
                                                navigate(
                                                    `/inner/cabinets/${item.uid}`
                                                )
                                            }
                                        >
                                            Voir la fiche
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                )}

                <Box mt={8}>
                    <Button
                        leftIcon={<AddIcon />}
                        type="button"
                        colorScheme={"red"}
                        size={"lg"}
                        w={"100%"}
                        onClick={() => addNewCabinet()}
                    >
                        Ajouter un cabinet
                    </Button>
                </Box>
            </RoundedBoxComponent>
        </>
    );
};

export default CabinetsPage;
