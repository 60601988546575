import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ProtectedRouteComponent from "./components/ProtectedRouteComponent";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import CabinetFormPage from "./pages/inner/CabinetFormPage";
import CabinetsPage from "./pages/inner/CabinetsPage";
import MedecinFormPage from "./pages/inner/MedecinFormPage";
import MedecinsPage from "./pages/inner/MedecinsPage";
import { AppProvider } from "./providers/AppProvider";
import MedecinFiche from "./pages/inner/MedecinFiche";
import ScrollToTopComponent from "./components/ScrollToTopComponent";
import NewPasswordPage from "./pages/NewPasswordPage";
import CgvPage from "./pages/CgvPage";

function App() {
    return (
        <>
            <ChakraProvider>
                <style>
                    {`
                    :root {
                        --chakra-fonts-body: "Inter" !important;
                        --chakra-fonts-heading: "Inter" !important;
                        --chakra-radii-md: 6px;
                        --chakra-fontSizes-md: 1.125rem;
                        --chakra-space-4: 26px;
                        --chakra-sizes-10: 40px;
                        --chakra-sizes-12: 56px;
                        --chakra-colors-chakra-body-bg: #F5F5F5 !important;
                        --font-secondary: "Muli";
                        --color-red: #E4343F;
                        --color-blue: #E2E8F0;
                    }

                    #root {
                        width: 100vw;
                        height: 100vh;
                    }
                `}
                </style>
                <AppProvider>
                    <BrowserRouter>
                        <ScrollToTopComponent />
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route
                                path="/register"
                                element={<RegisterPage />}
                            />
                            <Route path="/login" element={<LoginPage />} />
                            <Route
                                path="/reset-password"
                                element={<ResetPasswordPage />}
                            />
                            <Route
                                path="/new-password"
                                element={<NewPasswordPage />}
                            />
                            <Route
                                path="/conditions-generales-d-utilisation"
                                element={<CgvPage />}
                            />

                            <Route
                                path="/inner"
                                element={<ProtectedRouteComponent />}
                            >
                                <Route
                                    path="cabinets"
                                    element={<CabinetsPage />}
                                />
                                <Route
                                    path="cabinets/:id"
                                    element={<CabinetFormPage />}
                                />
                                <Route
                                    path="cabinets/:id/edit"
                                    element={<CabinetFormPage edit />}
                                />
                                <Route
                                    path="medecins"
                                    element={<MedecinsPage />}
                                />
                                <Route
                                    path="medecins/:id"
                                    element={<MedecinFiche />}
                                />
                                <Route
                                    path="medecins/:id/edit"
                                    element={<MedecinFormPage />}
                                />
                                <Route
                                    path=""
                                    element={<Navigate to="cabinets" />}
                                />
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </AppProvider>
            </ChakraProvider>
        </>
    );
}

export default App;
