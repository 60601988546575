import { Text, Flex, Image } from "@chakra-ui/react";
import { FC } from "react";

const TitleComponent: FC<{ title: string }> = ({ title }) => {
    return (
        <Flex direction={"column"} alignItems={"center"} my={4}>
            <Text
                textAlign={"center"}
                fontFamily={"var(--font-secondary)"}
                fontWeight={700}
                textTransform={"uppercase"}
                fontSize={"40px"}
            >
                {title}
            </Text>
            <Image src="/images/arc.svg" alt="" width={"158px"} />
        </Flex>
    );
};

export default TitleComponent;
