import {
    Box,
    Button,
    Center,
    Flex,
    Image,
    ListItem,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    UnorderedList,
    useToast,
} from "@chakra-ui/react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../providers/AppProvider";
import { ProtectedContext } from "../../providers/ProtectedProvider";
import { getMedecin } from "../../services/medecin.services";
import {
    deleteRelation,
    listRelationByMedecin,
} from "../../services/relation.services";
import { TRelationMedecin } from "../../types/relation";
import { getMyCabinetDetails } from "../../services/cabinet.services";

const MedecinCabinetsListComponent: FC = () => {
    const mountedRef = useRef(true);
    const { user } = useContext(AppContext);
    const toast = useToast();
    const {
        currentMedecin,
        isModalLink,
        setCurrentMedecin,
        setIsModalLink,
        setCurrentCabinet,
    } = useContext(ProtectedContext);
    const [tListRelation, setTListRelation] = useState<Array<TRelationMedecin>>(
        []
    );
    const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
    const [currentRelationId, setCurrentRelationId] = useState<number | null>(
        null
    );

    const getSolutionObject = (item: TRelationMedecin) => {
        switch (item.solution_resa) {
            case "alaxione":
                return {
                    label: "Alaxione",
                    value: item.solution_resa_alaxione,
                };
            case "doctolib":
                return {
                    label: "Doctolib",
                    value: item.solution_resa_doctolib,
                };
            case "custom":
                return {
                    label: "Ma propre solution de réservation",
                    value: item.solution_resa_custom,
                };
            case "phone":
                return {
                    label: "Prise de Rendez-vous par téléphone",
                    value: item.solution_resa_tel,
                };
            default:
                return { label: "", value: "" };
        }
    };

    const linkMedecin = async (uid_medecin: string | undefined) => {
        if (uid_medecin) {
            const reqMed = await getMedecin(uid_medecin);
            if (reqMed.length === 1) {
                setCurrentMedecin(reqMed[0]);
                setIsModalLink(true);
            }
        }
    };

    const editLinkMedecin = async (
        uid_medecin: string,
        uid_cabinet: string
    ) => {
        const getCurrentCabinet = await getMyCabinetDetails(
            user.uid,
            uid_cabinet
        );
        setCurrentCabinet(getCurrentCabinet[0]);
        const reqMed = await getMedecin(uid_medecin);
        setCurrentMedecin(reqMed[0]);
        setIsModalLink(true);
    };

    const executeDeleteRelation = async () => {
        if (currentRelationId && currentMedecin) {
            await deleteRelation(currentRelationId);
            toast({
                description: "Le lien avec le cabinet bien supprimé.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setIsOpenDelete(false);
            setTListRelation(await listRelationByMedecin([currentMedecin.id]));
        }
    };

    useEffect(() => {
        if (currentMedecin && !isModalLink) {
            setCurrentCabinet(null);
            (async () => {
                setTListRelation(
                    await listRelationByMedecin([currentMedecin.id])
                );
            })();
        }

        return () => {
            mountedRef.current = false;
        };
    }, [isModalLink]);

    return (
        <>
            {tListRelation.length === 0 && (
                <Center color={"#999999"}>La liste est vide.</Center>
            )}
            {currentMedecin && (
                <Stack gap={6}>
                    {tListRelation
                        .filter((item) => item.user.id === user.id)
                        .map((item, key) => (
                            <Box
                                key={key}
                                borderBottom={"1px solid var(--color-blue)"}
                                px={6}
                            >
                                <Flex alignItems={"center"} gap={2}>
                                    <Image src="/images/icon-house.svg" />
                                    <Text fontSize={"18px"} fontWeight={700}>
                                        {item.cabinet.nom}
                                    </Text>
                                    <Flex ml={"auto"} gap={2}>
                                        <Button
                                            variant={"outline"}
                                            colorScheme="dark"
                                            onClick={() =>
                                                editLinkMedecin(
                                                    item.medecin.uid,
                                                    item.cabinet.uid
                                                )
                                            }
                                        >
                                            Modifier
                                        </Button>
                                        <Button
                                            variant={"outline"}
                                            size={"sm"}
                                            colorScheme="dark"
                                            aria-label="Voir la relation"
                                            width={"38px"}
                                            height={"38px"}
                                            position={"relative"}
                                            ml={"auto"}
                                            onClick={() => {
                                                setCurrentRelationId(item.id);
                                                setIsOpenDelete(true);
                                            }}
                                        >
                                            <Image
                                                src={"/images/link-check.svg"}
                                                alt=""
                                                width={"35px"}
                                                height={"35px"}
                                                position={"absolute"}
                                                top={"6px"}
                                                left={"6px"}
                                            />
                                        </Button>
                                    </Flex>
                                </Flex>
                                <Box
                                    color={"#444444"}
                                    fontFamily={"var(--font-secondary)"}
                                    fontSize={"14px"}
                                    lineHeight={"24px"}
                                    mt={3}
                                >
                                    <Flex
                                        borderBottom={
                                            "1px solid var(--color-blue)"
                                        }
                                        py={3}
                                    >
                                        <Text width={"197px"}>
                                            Horaires d'injection
                                        </Text>
                                        <Text>{item.horaires_injection}</Text>
                                    </Flex>
                                    <Flex
                                        borderBottom={
                                            "1px solid var(--color-blue)"
                                        }
                                        py={3}
                                    >
                                        <Text width={"197px"}>
                                            Pièces indispensables au rendez-vous
                                        </Text>

                                        <UnorderedList>
                                            {item.piece_courrier_medecin && (
                                                <ListItem>
                                                    Courrier du médecin
                                                </ListItem>
                                            )}
                                            {item.piece_compte_rendu && (
                                                <ListItem>
                                                    Compte-rendu de la dernière
                                                    consultation
                                                </ListItem>
                                            )}
                                            {item.piece_last_oct && (
                                                <ListItem>Dernier OCT</ListItem>
                                            )}

                                            {item.piece_poss_prod_injec && (
                                                <ListItem>
                                                    Possession du produit à
                                                    injecter
                                                </ListItem>
                                            )}
                                        </UnorderedList>
                                    </Flex>
                                    <Flex py={3}>
                                        <Text width={"197px"}>
                                            Solution de réservation
                                        </Text>
                                        <Box>
                                            <Text>
                                                {getSolutionObject(item).label}
                                            </Text>
                                            <Text>
                                                {getSolutionObject(item).value}
                                            </Text>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        ))}
                    <Button
                        size={"xl"}
                        width={"100%"}
                        mt={"50px"}
                        variant={"outline"}
                        borderColor={"#999"}
                        bg={"#ffffff"}
                        onClick={() => linkMedecin(currentMedecin.uid)}
                    >
                        <Image
                            width={"40px"}
                            src="/images/link-add.svg"
                            alt=""
                        />
                        <Text
                            fontSize={"20px"}
                            fontWeight={600}
                            width={"350px"}
                            whiteSpace={"normal"}
                            py={4}
                        >
                            Associer ce médecin à mon cabinet et définir ses
                            horaires d'injection
                        </Text>
                    </Button>
                </Stack>
            )}

            <Modal
                isOpen={isOpenDelete}
                onClose={() => {
                    setIsOpenDelete(false);
                    setCurrentRelationId(null);
                }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Supprimer</ModalHeader>
                    <ModalBody>
                        Êtes-vous sûr de vouloir dissocier ce médecin de votre
                        cabinet ?
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            variant={"outline"}
                            mr={3}
                            onClick={() => setIsOpenDelete(false)}
                        >
                            Annuler
                        </Button>
                        <Button
                            colorScheme="red"
                            onClick={() => executeDeleteRelation()}
                        >
                            Dissocier
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default MedecinCabinetsListComponent;
