import {
    Avatar,
    Box,
    Button,
    Divider,
    Flex,
    Image,
    Stack,
    Text,
    useToast,
} from "@chakra-ui/react";
import { FC, useContext, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RoundedBoxComponent from "../../components/RoundedBoxComponent";
import TitleComponent from "../../components/TitleComponent";
import { ProtectedContext } from "../../providers/ProtectedProvider";
import { getMedecin } from "../../services/medecin.services";
import { baseUrl } from "../../services/services";
import MedecinCabinetsListComponent from "../../components/medecin/MedecinCabinetsListComponent";

const MedecinFiche: FC = () => {
    const mountedRef = useRef(true);
    const { id } = useParams<{ id: string }>();
    const toast = useToast();
    const navigate = useNavigate();
    const { currentMedecin, setCurrentMedecin } = useContext(ProtectedContext);

    useEffect(() => {
        if (id && id !== "0") {
            (async () => {
                const req = await getMedecin(id);

                if (req.length !== 1) {
                    toast({
                        title: "Erreur",
                        description: "Le contenu demandé n'est pas trouvé.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                    navigate("/inner/cabinets");
                } else {
                    const defValue = req[0];
                    setCurrentMedecin(defValue);
                }
            })();
        }

        return () => {
            setCurrentMedecin(null);
            mountedRef.current = false;
        };
    }, [id]);

    return (
        <>
            <TitleComponent title="Les médecins" />
            {currentMedecin && (
                <RoundedBoxComponent width="800px">
                    <Button
                        variant={"outline"}
                        pos={"absolute"}
                        top={"35px"}
                        right={"35px"}
                        onClick={() =>
                            navigate(
                                `/inner/medecins/${currentMedecin.uid}/edit`
                            )
                        }
                    >
                        Modifier
                    </Button>
                    <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        gap={3}
                    >
                        <Text fontSize={"22px"} fontWeight={700}>
                            Dr {currentMedecin.nom} {currentMedecin.prenom}
                        </Text>
                        <Avatar
                            size="2xl"
                            bg={"#E2E8F0"}
                            name={`${currentMedecin.nom} ${currentMedecin.prenom}`}
                            src={`${baseUrl}${currentMedecin.photo?.url}`}
                        />
                        <Text
                            maxW={"400px"}
                            textAlign={"center"}
                            fontSize={"14px"}
                            lineHeight={"26px"}
                        >
                            {currentMedecin.profession}
                        </Text>
                    </Stack>
                    <Divider my={6} />
                    <Flex
                        alignItems={"center"}
                        justifyContent={"center"}
                        gap={2}
                    >
                        <Image
                            src="/images/link-check.svg"
                            width={"40px"}
                            height={"40px"}
                            alt=""
                        />
                        <Text fontSize={"20px"} fontWeight={600}>
                            Le(s) cabinet(s) auxquel(s) le Dr est associé
                        </Text>
                    </Flex>
                    <Box mt={"50px"}>
                    <MedecinCabinetsListComponent />
                    </Box>
                </RoundedBoxComponent>
            )}
        </>
    );
};

export default MedecinFiche;
