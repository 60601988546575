import { ChevronRightIcon } from "@chakra-ui/icons";
import { Button, Stack, Text } from "@chakra-ui/react";
import { FC, useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ProtectedContext } from "../../providers/ProtectedProvider";
import { updateRelation } from "../../services/relation.services";
import FormsComponent from "../FormComponent";

type TForm = {
    piece_courrier_medecin: string;
    piece_compte_rendu: string;
    piece_last_oct: string;
    piece_poss_prod_injec: string;
};

const ModalPieceRdvComponent: FC = () => {
    const { currentRelations, setCurrentRelations, setActiveModalRelationTab } =
        useContext(ProtectedContext);

    const currentForm = useForm<TForm>();
    const onSubmit: SubmitHandler<TForm> = async (formData) => {
        if (currentRelations) {
            setCurrentRelations(
                await updateRelation({
                    ...formData,
                    id: currentRelations.id,
                })
            );
            setActiveModalRelationTab(2);
        }
    };

    return (
        <>
            {currentRelations && (
                <form onSubmit={currentForm.handleSubmit(onSubmit)}>
                    <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        gap={4}
                    >
                        <Text
                            fontSize={"22px"}
                            align={"center"}
                            fontWeight={700}
                        >
                            Pièces indispensables au rendez-vous
                        </Text>

                        <Stack width={"400px"} gap={3}>
                            <FormsComponent
                                form={currentForm}
                                name="piece_courrier_medecin"
                                type="checkbox"
                                label="Courrier du médecin"
                                defaultValue={
                                    currentRelations?.piece_courrier_medecin
                                }
                            />

                            <FormsComponent
                                form={currentForm}
                                name="piece_compte_rendu"
                                type="checkbox"
                                label="Compte-rendu de la dernière consultation"
                                defaultValue={
                                    currentRelations?.piece_compte_rendu
                                }
                            />

                            <FormsComponent
                                form={currentForm}
                                name="piece_last_oct"
                                type="checkbox"
                                label="Dernier OCT"
                                defaultValue={currentRelations?.piece_last_oct}
                            />

                            <FormsComponent
                                form={currentForm}
                                name="piece_poss_prod_injec"
                                type="checkbox"
                                label="Possession du produit à injecter"
                                defaultValue={
                                    currentRelations?.piece_poss_prod_injec
                                }
                            />
                        </Stack>
                        <Button
                            rightIcon={<ChevronRightIcon />}
                            size={"lg"}
                            colorScheme="red"
                            type="submit"
                            width={"100%"}
                        >
                            Valider et poursuivre
                        </Button>
                        <Button
                            size={"lg"}
                            variant={"outline"}
                            width={"100%"}
                            onClick={() => setActiveModalRelationTab(0)}
                        >
                            Retour
                        </Button>
                    </Stack>
                </form>
            )}
        </>
    );
};

export default ModalPieceRdvComponent;
