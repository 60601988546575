import { getToken } from "../helpers/session.helper";
import axios from "axios";

type Method = "GET" | "POST" | "PUT" | "DELETE";

type TAppRequest = {
    url: string;
    method?: Method;
    data?: Object;
    contentType?: string;
};

type TError = {
    status: number;
    message: string;
};

type TData<T = never> = {
    data?: T;
    error?: TError;
    user?: T;
    jwt?: string;
};

type TAppRequestResponse<T = never> = {
    data: TData<T> & T;
};

export const baseUrl = "https://api.ivtmobility.com";

/**
 * Make all app requests.
 * @param param
 * @returns
 */
export const appRequest = async <T>(
    param: TAppRequest
): Promise<TAppRequestResponse<T>> => {
    let headers: Record<string, string> = {
        "Content-Type": param.contentType || "application/json",
    };

    if (getToken()) {
        headers.Authorization = `Bearer ${getToken()}`;
    }

    const url = `${baseUrl}/api/${param.url}`;

    try {
        return await axios({
            method: param.method || "GET",
            data: param.data,
            headers,
            url,
        });
    } catch (err: any) {
        console.error(err);
        return {
            data: {
                ...err.response?.data,
            },
        };
    }
};

export const appRequestAbs = async <T>(param: TAppRequest): Promise<T> => {
    let headers: Record<string, string> = {
        "Content-Type": "application/json",
    };

    const url = param.url;

    try {
        return await axios({
            method: param.method || "GET",
            data: param.data,
            headers,
            url,
        });
    } catch (err: any) {
        console.error(err);
        return null as T;
    }
};
