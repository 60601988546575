import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Image, Text, useToast } from "@chakra-ui/react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { ProtectedContext } from "../../../providers/ProtectedProvider";
import { addImage, deleteMedia } from "../../../services/media.services";
import { baseUrl } from "../../../services/services";
import PopoverDeleteImage from "../../PopoverDeleteImage";
import { getMyCabinetDetails } from "../../../services/cabinet.services";
import { AppContext } from "../../../providers/AppProvider";

const InfoGenImagesComponent: FC = () => {
    const mountedRef = useRef(true);
    const { user } = useContext(AppContext);
    const { currentCabinet, setCurrentCabinet } = useContext(ProtectedContext);
    const inputLogoRef = useRef<HTMLInputElement>(null);
    const inputPhotosRef = useRef<HTMLInputElement>(null);
    const toast = useToast();

    const [logoUrl, setLogoUrl] = useState<string>("");
    const [photos, setPhotos] = useState<
        Array<{ id: number; filename: string; url: string }>
    >([]);

    const refreshCurrentCabinet = async () => {
        if (currentCabinet) {
            const getCurrent = await getMyCabinetDetails(user.uid, currentCabinet.uid);
            if (getCurrent.length === 1) {
                setCurrentCabinet(getCurrent[0]);
            }
        }
    };

    const addLogo = async () => {
        const tFiles = inputLogoRef.current?.files;

        if (tFiles) {
            if (currentCabinet) {
                const uploadLogo = await addImage({
                    files: tFiles[0],
                    refId: currentCabinet.id.toString(),
                    ref: "api::cabinet.cabinet",
                    field: "logo",
                });

                if (uploadLogo.url) {
                    setLogoUrl(uploadLogo.url);
                    await refreshCurrentCabinet();
                    toast({
                        description: "Logo bien ajouté.",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                    });
                }
            }
        }
    };

    const addPhoto = async () => {
        const tFiles = inputPhotosRef.current?.files;

        if (tFiles) {
            if (currentCabinet) {
                const uploadPhoto = await addImage({
                    files: tFiles[0],
                    refId: currentCabinet.id.toString(),
                    ref: "api::cabinet.cabinet",
                    field: "images",
                });

                if (uploadPhoto.url) {
                    setPhotos((prev) =>
                        prev.concat([
                            {
                                id: uploadPhoto.id,
                                filename: uploadPhoto.name,
                                url: uploadPhoto.url,
                            },
                        ])
                    );

                    await refreshCurrentCabinet();
                    toast({
                        description: "Photo bien ajoutée.",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                    });
                }
            }
        }
    };

    const deleteLogo = async () => {
        if (currentCabinet?.logo) {
            const req = await deleteMedia(currentCabinet.logo.id);

            if (req.id) {
                toast({
                    description: "Logo supprimé.",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                });
            }

            setLogoUrl("");
        }
    };

    const deletePhoto = async (id: number, index: number) => {
        const req = await deleteMedia(id);

        if (req.id) {
            toast({
                description: "Image supprimée.",
                status: "success",
                duration: 2000,
                isClosable: true,
            });
        }

        setPhotos((prev) => {
            const tPrev = prev;
            delete tPrev[index];
            return tPrev;
        });
    };

    useEffect(() => {
        if (currentCabinet?.logo?.id) {
            setLogoUrl(currentCabinet.logo.url);
        } else {
            setLogoUrl("");
        }

        if (currentCabinet?.images) {
            setPhotos(currentCabinet.images);
        } else {
            setPhotos([]);
        }

        return () => {
            mountedRef.current = false;
        };
    }, [currentCabinet?.uid]);

    return (
        <>
            <Box
                width={"100%"}
                borderRadius={"12px"}
                border={"1px var(--color-blue) solid"}
                p={4}
            >
                <Text
                    fontSize={"16px"}
                    fontWeight={700}
                    color={"#444444"}
                    textAlign={"center"}
                >
                    Télécharger le logo du cabinet
                </Text>
                <input
                    type="file"
                    accept="image/png, image/jpeg"
                    ref={inputLogoRef}
                    style={{ display: "none" }}
                    onChange={() => addLogo()}
                ></input>
                <Text
                    fontSize={"12px"}
                    fontStyle={"italic"}
                    color={"#444444"}
                    textAlign={"center"}
                >
                    Format accepté : jpg, png. Poids maximal : 1 Mo.
                </Text>
                {!logoUrl && (
                    <Box mt={4} textAlign={"center"}>
                        <Button
                            onClick={() => inputLogoRef.current?.click()}
                            leftIcon={<AddIcon color={"var(--color-red)"} />}
                            variant={"outline"}
                        >
                            Ajouter un logo
                        </Button>
                    </Box>
                )}

                {logoUrl && (
                    <Flex
                        textAlign={"center"}
                        mt={"30px"}
                        mx={"auto"}
                        pos={"relative"}
                        width={"200px"}
                        height={"200px"}
                        border={"1px var(--color-blue) solid"}
                        p={2}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <PopoverDeleteImage action={deleteLogo} />
                        <Image
                            src={`${baseUrl}${logoUrl}`}
                            alt=""
                            maxW={"176px"}
                            maxH={"176px"}
                            loading="lazy"
                        />
                    </Flex>
                )}
            </Box>
            <Box
                width={"100%"}
                borderRadius={"12px"}
                border={"1px var(--color-blue) solid"}
                p={4}
                mt={4}
            >
                <input
                    type="file"
                    accept="image/png, image/jpeg"
                    ref={inputPhotosRef}
                    style={{ display: "none" }}
                    onChange={() => addPhoto()}
                ></input>
                <Text
                    fontSize={"16px"}
                    fontWeight={700}
                    color={"#444444"}
                    textAlign={"center"}
                >
                    Télécharger les photos du cabinet
                </Text>
                <Text
                    fontSize={"12px"}
                    fontStyle={"italic"}
                    color={"#444444"}
                    textAlign={"center"}
                >
                    Format accepté : jpg, png. Poids maximal : 1 Mo.
                </Text>

                {photos.length > 0 && (
                    <Flex
                        dir="row"
                        gap={"40px"}
                        flexWrap={"wrap"}
                        mt={"30px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        {photos.map((item, key) => (
                            <Box
                                key={key}
                                pos={"relative"}
                                width={"120px"}
                                height={"80px"}
                                border={"1px var(--color-blue) solid"}
                            >
                                <PopoverDeleteImage
                                    action={() => deletePhoto(item.id, key)}
                                />
                                <Image
                                    src={`${baseUrl}${item.url}`}
                                    alt=""
                                    w={"100%"}
                                    h={"100%"}
                                    objectFit={"cover"}
                                    loading="lazy"
                                />
                            </Box>
                        ))}
                    </Flex>
                )}

                <Box mt={4} textAlign={"center"}>
                    <Button
                        leftIcon={<AddIcon color={"var(--color-red)"} />}
                        variant={"outline"}
                        onClick={() => inputPhotosRef.current?.click()}
                    >
                        Ajouter des photos du cabinet
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default InfoGenImagesComponent;
