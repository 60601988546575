import { Box, Button, Divider, Image, Stack, Text, useToast } from "@chakra-ui/react";
import { FC, useEffect, useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import FormsComponent from "../components/FormComponent";
import RoundedBoxComponent from "../components/RoundedBoxComponent";
import { isLogged } from "../helpers/session.helper";
import { forgotPassword } from "../services/user.services";

type TResetPassword = {
    email: string;
};
const ResetPasswordPage: FC = () => {
    const mountedRef = useRef(true);
    const navigate = useNavigate();
    const recoveryForm = useForm<TResetPassword>();
    const toast = useToast();

    const onSubmit: SubmitHandler<TResetPassword> = async (formData) => {
        const reqForgot = await forgotPassword(formData.email);

        if (reqForgot.error) {
            toast({
                title: "Réinitialisation de mot de passe",
                description: "Impossible d'effectuer votre requette.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (reqForgot.ok === true) {
            toast({
                title: "Réinitialisation de mot de passe",
                description: "Votre demande a été prise en compte. Veuillez consulter votre boite e-mail.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            navigate("/login");
        }
    };

    useEffect(() => {
        if (isLogged()) {
            navigate("/inner");
        }

        return () => {
            mountedRef.current = false;
        };
    }, []);

    return (
        <RoundedBoxComponent centerVertical>
            <Box>
                <Image
                    src="/images/logo.svg"
                    alt=""
                    width={"236px"}
                    mx={"auto"}
                    mb={8}
                />
            </Box>
            <Divider width={"300px"} mx={"auto"} mb={3} />
            <Text
                textTransform={"uppercase"}
                textAlign={"center"}
                fontSize={"20px"}
                fontWeight={700}
            >
                Réinitialisation du mot de passe
            </Text>
            <form onSubmit={recoveryForm.handleSubmit(onSubmit)}>
                <Stack spacing={8} direction={"column"} my={5}>
                    <FormsComponent
                        form={recoveryForm}
                        name="email"
                        label="Email"
                        placeholder="Email"
                        type="email"
                        required={true}
                    />

                    <Box textAlign={"center"}>
                        <Button
                            type="submit"
                            colorScheme={"red"}
                            size={"lg"}
                            w={"100%"}
                        >
                            Réinitialiser le mot de passe
                        </Button>
                    </Box>
                    <Box
                        textAlign={"center"}
                        fontSize={"18px"}
                        transition={"all .25s ease-in-out"}
                        _hover={{ textDecor: "underline" }}
                    >
                        <Link to={"/"}>
                            <Box as="span">Retour vers l'accueil</Box>
                        </Link>
                    </Box>
                </Stack>
            </form>
        </RoundedBoxComponent>
    );
};

export default ResetPasswordPage;
