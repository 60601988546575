import { AddIcon } from "@chakra-ui/icons";
import {
    Alert,
    AlertIcon,
    Avatar,
    Box,
    Button,
    Flex,
    Image,
    Table,
    Tbody,
    Td,
    Text,
    Tr,
} from "@chakra-ui/react";
import {
    FC,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";
import RoundedBoxComponent from "../../components/RoundedBoxComponent";
import TitleComponent from "../../components/TitleComponent";
import { AppContext } from "../../providers/AppProvider";
import { ProtectedContext } from "../../providers/ProtectedProvider";
import { getMedecin, listAllMedecins } from "../../services/medecin.services";
import { listRelationByMedecin } from "../../services/relation.services";
import { baseUrl } from "../../services/services";
import { TMedecin } from "../../types/medecin";
import { TRelationMedecin } from "../../types/relation";

const MedecinsPage: FC = () => {
    const mountedRef = useRef(true);
    const { user } = useContext(AppContext);
    const { setIsModalLink, setCurrentMedecin } = useContext(ProtectedContext);
    const [tMedecins, setTMedecins] = useState<Array<TMedecin>>([]);
    const [tRelations, setTRelations] = useState<Array<TRelationMedecin>>([]);
    const navigate = useNavigate();

    const isLinked = useCallback(
        (id_medecin: number) => {
            return tRelations.some((item) => {
                return item.cabinet.id && item.medecin.id === id_medecin;
            });
        },
        [tRelations.length]
    );

    const linkMedecin = async (uid_medecin: string) => {
        const reqMed = await getMedecin(uid_medecin);
        if (reqMed.length === 1) {
            setCurrentMedecin(reqMed[0]);
            setIsModalLink(true);
        }
    };

    useEffect(() => {
        (async () => {
            setTMedecins(await listAllMedecins(user.uid));
        })();

        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        if (tMedecins.length > 0) {
            (async () => {
                setTRelations(
                    await listRelationByMedecin(
                        tMedecins.map((item) => item.id)
                    )
                );
            })();
        }

        return () => {
            mountedRef.current = false;
        };
    }, [tMedecins.length]);

    return (
        <>
            <TitleComponent title="Les médecins" />
            <RoundedBoxComponent width="800px">
                <Text
                    as={"h1"}
                    textAlign={"center"}
                    fontWeight={700}
                    fontSize={"22px"}
                    mb={5}
                >
                    Le(s) médecin(s) associé(s) à mon compte
                </Text>
                {tMedecins.length === 0 && (
                    <Alert status="info">
                        <AlertIcon />
                        Aucun médecin n'est encore renseigné
                    </Alert>
                )}

                {tMedecins.length > 0 && (
                    <Table variant="simple">
                        <Tbody>
                            {tMedecins.map((item, key) => (
                                <Tr key={key}>
                                    <Td>
                                        <Flex alignItems={"center"} gap={2}>
                                            <Avatar
                                                name={`${item.nom} ${item.prenom}`}
                                                src={
                                                    item.photo?.url
                                                        ? `${baseUrl}${item.photo.url}`
                                                        : undefined
                                                }
                                                bg={"#E2E8F0"}
                                            />
                                            <Text
                                                fontSize={"18px"}
                                                fontWeight={700}
                                            >
                                                {item.nom} {item.prenom}
                                            </Text>
                                        </Flex>
                                    </Td>
                                    <Td w={"50px"} p={1}>
                                        <Button
                                            variant={"outline"}
                                            size={"sm"}
                                            colorScheme="dark"
                                            onClick={() =>
                                                linkMedecin(item.uid)
                                            }
                                            aria-label="Voir la relation"
                                            width={"40px"}
                                            height={"40px"}
                                            position={"relative"}
                                        >
                                            <Image
                                                src={`/images/${
                                                    isLinked(item.id)
                                                        ? "link-check.svg"
                                                        : "link-add.svg"
                                                }`}
                                                alt=""
                                                width={"35px"}
                                                height={"35px"}
                                                position={"absolute"}
                                                top={"6px"}
                                                left={"6px"}
                                            />
                                        </Button>
                                    </Td>
                                    <Td w={"100px"} p={1}>
                                        <Button
                                            colorScheme="red"
                                            height={"40px"}
                                            onClick={() =>
                                                navigate(
                                                    `/inner/medecins/${item.uid}`
                                                )
                                            }
                                        >
                                            Voir la fiche
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                )}

                <Box mt={8}>
                    <Button
                        leftIcon={<AddIcon />}
                        type="button"
                        colorScheme={"red"}
                        size={"lg"}
                        w={"100%"}
                        onClick={() => navigate(`/inner/medecins/0/edit`)}
                    >
                        Ajouter un médecin
                    </Button>
                </Box>
            </RoundedBoxComponent>
        </>
    );
};

export default MedecinsPage;
