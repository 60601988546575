import { AddIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, Spinner, Stack, Text, Center } from "@chakra-ui/react";
import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../providers/AppProvider";
import { ProtectedContext } from "../../providers/ProtectedProvider";
import {
    insertNewRelation,
    listRelationByMedecin,
    updateRelation,
} from "../../services/relation.services";
import { TRelationMedecin } from "../../types/relation";
import FormsComponent from "../FormComponent";

type TForm = {
    id_cabinet: number;
    id_medecin: number;
    horaires_injection: string;
};

const ModalCabinetComponent: FC = () => {
    const mountedRef = useRef(true);
    const { user } = useContext(AppContext);
    const [tListRelation, setTListRelation] = useState<Array<TRelationMedecin>>(
        []
    );
    const navigate = useNavigate();
    const [isready, setIsReady] = useState<boolean>(false);
    const {
        currentCabinet,
        cabinets,
        currentRelations,
        isModalLink,
        currentMedecin,
        setCurrentRelations,
        setActiveModalRelationTab,
        setIsModalLink,
    } = useContext(ProtectedContext);
    const currentForm = useForm<TForm>();

    const onSubmit: SubmitHandler<TForm> = async (formData) => {
        if (currentMedecin) {
            formData.id_medecin = currentMedecin.id;
            formData.id_cabinet = +formData.id_cabinet;

            if (currentRelations) {
                setCurrentRelations(
                    await updateRelation({
                        id: currentRelations.id,
                        medecin: formData.id_medecin,
                        cabinet: formData.id_cabinet,
                        user: user.id,
                        horaires_injection: formData.horaires_injection,
                    })
                );
            } else {
                if (user.id) {
                    setCurrentRelations(
                        await insertNewRelation({
                            medecin: formData.id_medecin,
                            cabinet: formData.id_cabinet,
                            user: user.id,
                            horaires_injection: formData.horaires_injection,
                        })
                    );
                }
            }

            setActiveModalRelationTab(1);
        }
    };

    const availableCabinet = useMemo(() => {
        return cabinets.filter((item) => {
            // If edit, enable
            if (currentCabinet?.id) {
                return true;
            }

            // return only not linked cabinet
            return !tListRelation.some((elm) => elm.cabinet.id === item.id);
        });
    }, [cabinets, tListRelation]);

    useEffect(() => {
        setIsReady(false);
        if (currentMedecin) {
            (async () => {
                setTListRelation(
                    await listRelationByMedecin([currentMedecin.id])
                );

                setTimeout(() => setIsReady(true), 1000);
            })();
        }

        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        if (currentRelations) {
            currentForm.reset({
                id_cabinet: currentRelations.cabinet.id,
                id_medecin: currentRelations.medecin.id,
                horaires_injection: currentRelations.horaires_injection,
            });
        }

        return () => {
            mountedRef.current = false;
        };
    }, [currentRelations?.id]);

    useEffect(() => {
        if (isModalLink) {
            if (currentMedecin) {
                currentForm.reset({ id_medecin: currentMedecin.id });
            }

            if (currentCabinet) {
                currentForm.reset({ id_cabinet: currentCabinet.id });
            }
        }

        return () => {
            mountedRef.current = false;
        };
    }, [isModalLink, currentMedecin?.id, currentCabinet?.id]);

    return (
        <>
            {!isready && (
                <Center p={6}>
                    <Spinner color="red" size={"lg"}></Spinner>
                </Center>
            )}
            {isready && (
                <>
                    {availableCabinet.length === 0 && (
                        <Stack
                            color={"#999999"}
                            my={6}
                            alignItems={"center"}
                            gap={6}
                        >
                            <Text
                                textAlign={"center"}
                                lineHeight={"28px"}
                                fontSize={"18px"}
                                width={"70%"}
                                mx={"auto"}
                            >
                                Vous ne pouvez plus d'avantage ajouter une
                                association car tous vos cabinets sont déjà liés
                                à ce médecin.
                            </Text>
                            <Button
                                type="button"
                                variant={"outline"}
                                size={"lg"}
                                w={"100%"}
                                onClick={() => {
                                    setIsModalLink(false);
                                    navigate(
                                        `/inner/medecins/${currentMedecin?.uid}`
                                    );
                                }}
                            >
                                Consulter la fiche du médecin
                            </Button>
                            <Button
                                leftIcon={<AddIcon />}
                                type="button"
                                colorScheme={"red"}
                                size={"lg"}
                                w={"100%"}
                                onClick={() => {
                                    setIsModalLink(false);
                                    navigate("/inner/cabinets/0/edit");
                                }}
                            >
                                Ajouter un nouveau cabinet
                            </Button>
                        </Stack>
                    )}
                    {availableCabinet.length > 0 && (
                        <form onSubmit={currentForm.handleSubmit(onSubmit)}>
                            <Stack w={"600px"} mx={"auto"} gap={4}>
                                <Box>
                                    <Text
                                        fontSize={"22px"}
                                        align={"center"}
                                        fontWeight={700}
                                        mb={2}
                                    >
                                        Cabinet d'association
                                    </Text>

                                    <FormsComponent
                                        form={currentForm}
                                        type="select"
                                        name="id_cabinet"
                                        readonly={!!currentCabinet?.id}
                                        defaultValue={currentCabinet?.id}
                                        options={availableCabinet.map(
                                            (item) => ({
                                                key: item.id,
                                                label: item.nom,
                                            })
                                        )}
                                        required
                                    />
                                </Box>
                                <Box>
                                    <Text
                                        fontSize={"22px"}
                                        align={"center"}
                                        fontWeight={700}
                                        mb={2}
                                    >
                                        Jours et horaires d'injection
                                    </Text>

                                    <FormsComponent
                                        form={currentForm}
                                        type="textarea"
                                        name="horaires_injection"
                                        required
                                    />
                                </Box>
                                <Button
                                    rightIcon={<ChevronRightIcon />}
                                    size={"lg"}
                                    colorScheme="red"
                                    type="submit"
                                >
                                    Valider et poursuivre
                                </Button>
                            </Stack>
                        </form>
                    )}
                </>
            )}
        </>
    );
};

export default ModalCabinetComponent;
