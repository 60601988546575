import {
    Box,
    Button,
    Flex,
    Image,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    useToast,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProtectedContext } from "../../../providers/ProtectedProvider";
import { baseUrl } from "../../../services/services";
import { deleteCabinet } from "../../../services/cabinet.services";

const InfoGenDetailsComponent = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const { currentCabinet } = useContext(ProtectedContext);
    const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

    const executeDeleteCabinet = async () => {
        if (currentCabinet) {
            await deleteCabinet(currentCabinet.id);
            toast({
                description: "Cabinet bien supprimé.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            navigate("/inner/cabinets");
        }
    };

    return (
        <>
            {currentCabinet && (
                <>
                    <Text textAlign={"center"} fontWeight={700} fontSize={"22"}>
                        Informations du cabinet
                    </Text>
                    <Stack>
                        <Flex mt={6} gap={"30px"}>
                            <Box width={"176px"} flexShrink={0}>
                                {currentCabinet.logo?.url ? (
                                    <Image
                                        src={`${baseUrl}${currentCabinet.logo.url}`}
                                        alt=""
                                        maxW={"176px"}
                                        maxH={"176px"}
                                        loading="lazy"
                                    />
                                ) : (
                                    <Image
                                        src="/images/default-logo.svg"
                                        alt=""
                                    />
                                )}
                            </Box>
                            <Stack
                                fontSize={"16px"}
                                lineHeight={"24px"}
                                gap={"20px"}
                            >
                                <Box>
                                    <Text fontWeight={700}>
                                        {currentCabinet.nom}
                                    </Text>
                                    <Text>{currentCabinet.adresse}</Text>
                                    <Text>{currentCabinet.adresse_comp_1}</Text>
                                    <Text>{currentCabinet.adresse_comp_2}</Text>
                                    <Text>
                                        {currentCabinet.code_postal}{" "}
                                        {currentCabinet.ville}
                                    </Text>
                                </Box>
                                <Box>
                                    <Text fontWeight={700}>Contact</Text>
                                    <Text>{currentCabinet.telephone}</Text>
                                    <Text>{currentCabinet.email}</Text>
                                </Box>
                                <Box>
                                    <Text fontWeight={700}>Accès</Text>
                                    {currentCabinet.acces_handicapes ===
                                        "oui" && <Text>Accès handicapé</Text>}
                                    {currentCabinet.parking === "gratuit" && (
                                        <Text>Parking gratuit</Text>
                                    )}
                                    {currentCabinet.parking === "payant" && (
                                        <Text>Parking payant</Text>
                                    )}
                                    {currentCabinet.parking ===
                                        "reserve_patients" && (
                                        <Text>
                                            Parking réservé pour patients
                                        </Text>
                                    )}
                                    {currentCabinet.transport_commun && (
                                        <Text>Avec transport en commun</Text>
                                    )}
                                </Box>
                                {currentCabinet.parking_description && (
                                    <Box>
                                        {currentCabinet.parking_description}
                                    </Box>
                                )}

                                {currentCabinet.transport_commun_desc && (
                                    <Box>
                                        {currentCabinet.transport_commun_desc}
                                    </Box>
                                )}

                                {currentCabinet.images &&
                                    currentCabinet.images.length > 0 && (
                                        <Box>
                                            <Text fontWeight={700}>
                                                Photo(s) du cabinet
                                            </Text>
                                            <Flex
                                                flexWrap={"wrap"}
                                                gap={3}
                                                mt={2}
                                            >
                                                {currentCabinet.images.map(
                                                    (item, key) => (
                                                        <Image
                                                            key={key}
                                                            src={`${baseUrl}${item.url}`}
                                                            alt=""
                                                            loading="lazy"
                                                            width={"120px"}
                                                            height={"80px"}
                                                            objectFit={"cover"}
                                                        />
                                                    )
                                                )}
                                            </Flex>
                                        </Box>
                                    )}
                            </Stack>
                        </Flex>
                        <Button
                            colorScheme="red"
                            size={"lg"}
                            mt={5}
                            onClick={() =>
                                navigate(
                                    `/inner/cabinets/${currentCabinet.uid}/edit`
                                )
                            }
                        >
                            Modifier les informations
                        </Button>
                        <Button
                            variant={"outline"}
                            size={"lg"}
                            mt={3}
                            onClick={() => setIsOpenDelete(true)}
                        >
                            Supprimer
                        </Button>
                    </Stack>

                    <Modal
                        isOpen={isOpenDelete}
                        onClose={() => setIsOpenDelete(false)}
                    >
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Supprimer</ModalHeader>
                            <ModalBody>
                                Voulez-vous vraiment supprimer ce cabinet ?
                            </ModalBody>

                            <ModalFooter>
                                <Button
                                    variant={"outline"}
                                    mr={3}
                                    onClick={() => setIsOpenDelete(false)}
                                >
                                    Annuler
                                </Button>
                                <Button
                                    colorScheme="red"
                                    onClick={() => executeDeleteCabinet()}
                                >
                                    Supprimer
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </>
            )}
        </>
    );
};

export default InfoGenDetailsComponent;
