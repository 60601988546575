import { TRelationMedecin } from "../types/relation";
import { appRequest } from "./services";

const getReturnedData = (dataRes: any) => {
    return {
        id: dataRes.id,
        horaires_injection: dataRes.attributes.horaires_injection,
        medecin: {
            id: dataRes.attributes.medecin.data?.id,
            uid: dataRes.attributes.medecin.data?.attributes.uid,
            nom: dataRes.attributes.medecin.data?.attributes.nom,
            prenom: dataRes.attributes.medecin.data?.attributes.prenom,
        },
        cabinet: {
            id: dataRes.attributes.cabinet.data?.id,
            uid: dataRes.attributes.cabinet.data?.attributes.uid,
            nom: dataRes.attributes.cabinet.data?.attributes.nom,
        },
        user: {
            id: dataRes.attributes.user.data.id,
        },
        piece_compte_rendu: dataRes.attributes.piece_compte_rendu,
        piece_courrier_medecin: dataRes.attributes.piece_courrier_medecin,
        piece_last_oct: dataRes.attributes.piece_last_oct,
        piece_poss_prod_injec: dataRes.attributes.piece_poss_prod_injec,
        solution_resa: dataRes.attributes.solution_resa,
        solution_resa_alaxione: dataRes.attributes.solution_resa_alaxione,
        solution_resa_custom: dataRes.attributes.solution_resa_custom,
        solution_resa_doctolib: dataRes.attributes.solution_resa_doctolib,
        solution_resa_tel: dataRes.attributes.solution_resa_tel,
    } as TRelationMedecin;
};

export const listRelationByMedecin = async (tIdMedecin: Array<number>) => {
    let tFilter = "";

    tIdMedecin.forEach((item, key) => {
        tFilter += `&filters[$or][${key}][medecin][id][$eq]=${item}`;
    });

    const currentRequest = await appRequest<{
        data: Array<{ id: number; attributes: any }>;
    }>({
        url: `relation-medecin-cabinets?populate=*&filters[cabinet][status][$eq]=published${tFilter}`,
    });

    return currentRequest.data.data.map((item) => {
        return getReturnedData(item);
    }) as Array<TRelationMedecin>;
};

export const listRelationByCabinet = async (id_cabinet: number) => {
    const currentRequest = await appRequest<{
        data: Array<{ id: number; attributes: any }>;
    }>({
        url: `relation-medecin-cabinets?populate=*&filters[cabinet][status][$eq]=published&filters[cabinet][id][$eq]=${id_cabinet}`,
    });

    return currentRequest.data.data.map((item) => {
        return getReturnedData(item);
    }) as Array<TRelationMedecin>;
};

export const getRelationByCabinetMedecin = async (
    id_cabinet: number,
    id_medecin: number
) => {
    const currentRequest = await appRequest<{
        data: Array<{ id: number; attributes: any }>;
    }>({
        url: `relation-medecin-cabinets?populate=*&filters[cabinet][status][$eq]=published&filters[$and][0][cabinet][id][$eq]=${id_cabinet}&filters[$and][1][medecin][id][$eq]=${id_medecin}`,
    });

    return currentRequest.data.data.map((item) => {
        return getReturnedData(item);
    }) as Array<TRelationMedecin>;
};

export const insertNewRelation = async (data: {
    medecin: number;
    cabinet: number;
    user: number;
    horaires_injection: string;
}) => {
    const currentRequest = await appRequest<{
        data: { id: number; attributes: any };
    }>({
        url: `relation-medecin-cabinets?populate=*`,
        method: "POST",
        data: {
            data,
        },
    });
    const dataRes = currentRequest.data.data;
    return getReturnedData(dataRes) as TRelationMedecin;
};

export const updateRelation = async (data: any) => {
    const currentRequest = await appRequest<{
        data: { id: number; attributes: any };
    }>({
        url: `relation-medecin-cabinets/${data.id}?populate=*`,
        method: "PUT",
        data: {
            data,
        },
    });
    const dataRes = currentRequest.data.data;
    return getReturnedData(dataRes) as TRelationMedecin;
};

export const deleteRelation = async (idRelation: number) => {
    const currentRequest = await appRequest<{
        data: { id: number; attributes: any };
    }>({
        url: `relation-medecin-cabinets/${idRelation}`,
        method: "DELETE",
    });
    return currentRequest.data.data;
}
