import { FC, useContext, useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { isLogged, logoutUser } from "../helpers/session.helper";
import { AppContext } from "../providers/AppProvider";
import { ProtectedProvider } from "../providers/ProtectedProvider";
import { getCurrentUser } from "../services/user.services";

const ProtectedRouteComponent: FC = () => {
    const mountedRef = useRef(true);
    const navigate = useNavigate();
    const { user, setUser } = useContext(AppContext);

    useEffect(() => {
        if (!isLogged()) {
            navigate("/login");
        } else if (!user.id) {
            (async () => {
                const reqUser = await getCurrentUser();

                if (reqUser.error && reqUser.error.status === 401) {
                    logoutUser();
                    navigate("/login");
                } else {
                    setUser(reqUser);
                }
            })();
        }

        return () => {
            mountedRef.current = false;
        };
    }, []);

    return (
        <>
            {!!user.id && (
                <ProtectedProvider>
                    <Outlet />
                </ProtectedProvider>
            )}
        </>
    );
};

export default ProtectedRouteComponent;
